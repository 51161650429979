import {
  ArchiveBoxXMarkIcon,
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useDownload from '../hooks/useDownload';
import { Client, MarketplaceData, Product } from '../components/common/types';
import BaseContext from '../components/common/BaseContext';
import { Listbox } from '@headlessui/react';
import SlideOver from '../components/common/SlideOver';
import { useParams } from 'react-router-dom';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import ProductTable from '../components/Products/ProductTable';
import ProductFilter from '../components/Products/ProductFilter';
import { getMarketplace, MARKETPLACE_DATA } from '../utils/ConnectionConfig';
import pLimit from 'p-limit';

const Products = () => {
  const authenticatedDownload = useDownload();
  const [searchText, setSearchText] = useState('');
  const [bundleSearchText, setBundleSearchText] = useState('');
  const [asin, setAsin] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const { organization, setLoading, setShowNotification, loading, setOrganization } =
    useContext(BaseContext);
  const [selectedMerchants, setSelectedMerchants] = useState<Client[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [bundlingProducts, setBundlingProducts] = useState([]);
  const [merchants, setMerchants] = useState([]);
  const [amazonSelect, setAmazonSelect] = useState(false);

  const [bundleItem, setBundleItem] = useState<Product | null>(null);
  const [bundleSlideoverOpen, setBundleSlideoverOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [integration, setIntegration] = useState(null);

  const [displayProducts, setDisplayProducts] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [marketplaces, setMarketplaces] = useState<MarketplaceData>();
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const [selectedIntegrationNames, setSelectedIntegrationNames] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const {
    executeRequest: fetchProductListings,

    pending: productsLoading,
  } = useRequest(apiPaths.LISTINGS, []);
  const { executeRequest: fetchIntegrationsData } = useRequest(apiPaths.GET_INTEGRATIONS, []);
  const { executeRequest: fetchMerchantsData } = useRequest(apiPaths.GET_MERCHANTS, []);
  const { executeRequest: setBundleItems } = useRequest(apiPaths.BUNDLES, []);
  const { executeRequest: setSearchCatalog } = useRequest(apiPaths.SEARCH_MARKETPLACE_CATALOG, []);
  const { executeRequest: updateListingRequest } = useRequest(
    apiPaths.UPDATE_LISTING_PREP_INSTRUCTIONS,
    [],
  );

  const integrationSources = ['Amazon', 'Shopify'];
  const allSelectedAreAmazon = selectedProductIds.every(
    (listingId) =>
      products.find((product) => product.listingId === listingId)?.integrationName === 'Amazon',
  );

  //States
  const pageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set<number>());

  const params = useParams();
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (params.orgId && organization === null) {
        setLoading(true);

        try {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };

          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          if (organizationsResponse.success && organizationsResponse.data) {
            const org = organizationsResponse?.data.find(
              (org) => org.organizationId === params.orgId,
            );
            setOrganization(org);
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (bundleItem) setBundleSlideoverOpen(true);
    else setBundleSlideoverOpen(false);
  }, [bundleItem]);

  useEffect(() => {
    if (!bundleSlideoverOpen) setBundleItem(null);
    // else setBundleSlideoverOpen(false)
  }, [bundleSlideoverOpen]);

  const getParams = useCallback(() => {
    const params: Record<string, string> = {};
    if (selectedMerchants.length > 0) {
      params['filter[merchantId]'] = selectedMerchants
        .map((merchant) => merchant.merchantId)
        .join(',');
    } else if (merchants.length > 0) {
      params['filter[merchantId]'] = merchants.map((merchant) => merchant.merchantId).join(',');
    }
    if (selectedStatuses.length > 0) {
      params['filter[status]'] = selectedStatuses.map((status) => status.value).join(',');
    }

    if (selectedMarketplaces.length > 0) {
      params['filter[marketplace]'] = selectedMarketplaces
        .map((marketplace) => marketplace.value)
        .join(',');
    }

    if (selectedIntegrationNames.length > 0) {
      params['filter[integrationName]'] = selectedIntegrationNames
        .map((integrationName) => integrationName.value)
        .join(',');
    }

    if (searchText.length > 0) {
      params.search = searchText;
    }
    return params;
  }, [
    selectedMerchants,
    selectedMarketplaces,
    selectedStatuses,
    searchText,
    merchants,
    selectedIntegrationNames,
  ]);

  const pendingRequestsRef = useRef<AbortController[]>([]);

  const fetchProducts = useCallback(
    async (page: number) => {
      if (loading || merchants.length === 0) return;
      if (fetchedPagesRef.current.has(page)) return;

      // Create a new AbortController for this fetch
      const controller = new AbortController();
      const { signal } = controller;

      try {
        // If it's the initial fetch (page 0), cancel all pending requests
        if (page === 0) {
          setProducts([]);
          // Cancel all pending requests
          pendingRequestsRef.current.forEach((controller) => controller.abort());
          pendingRequestsRef.current = [];
        }

        // Add this controller to the list of pending requests
        pendingRequestsRef.current.push(controller);

        const params = {
          ...getParams(),
          include: 'merchant,bundleListing,integration',
          limit: '20',
          page: (page + 1).toString(),
        };

        const productListingsResponse = await fetchProductListings(
          {
            queryParams: params,
            urlParams: {},
          },
          {
            signal, // Pass the signal to the fetch call
          },
        );

        if (productListingsResponse.success) {
          setProducts((prevProducts) => {
            const newProducts = productListingsResponse.data.filter(
              (product: Product) => !prevProducts.some((p) => p.listingId === product.listingId),
            );
            return [...prevProducts, ...newProducts];
          });

          setHasMore(productListingsResponse.data.length > 0);
          fetchedPagesRef.current.add(page);
          pageRef.current = page + 1;
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error fetching products', error);
        }
      } finally {
        setLoading(false);
        // Remove this controller from the list of pending requests
        pendingRequestsRef.current = pendingRequestsRef.current.filter((c) => c !== controller);
      }
    },
    [getParams, loading, merchants.length],
  );
  const fetchBundlingProducts = async () => {
    if (!bundleSearchText) return;

    try {
      const productListingsResponse = await fetchProductListings({
        urlParams: {},
        queryParams: { search: bundleSearchText },
      });

      if (productListingsResponse.success) {
        setBundlingProducts(productListingsResponse.data);
      }
    } catch (error) {
      console.error('Error while fetching products', error);
    }
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      if (merchants.length > 0) {
        try {
          const integrationResponse = await fetchIntegrationsData({
            queryParams: {
              'filter[merchantId]': merchants.map((merchant) => merchant.merchantId).join(','),
            },
            urlParams: {},
          });

          if (integrationResponse.success) {
            setIntegration(integrationResponse.data[0]);
          }
        } catch (error) {
          console.error('Error while fetching integrations', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchIntegrations();
  }, [merchants]);

  useEffect(() => {
    const enabledMarketplaces = integration?.connections.map(
      (connection) => connection.details?.marketplaceId,
    );
    setMarketplaces(
      enabledMarketplaces?.map((marketplace) => {
        return {
          label: MARKETPLACE_DATA.find((data) => data.marketplaceId === marketplace)?.country,
          value: marketplace,
        };
      }),
    );
  }, [integration]);

  const statuses = ['active', 'deleted', 'inactive'];

  useEffect(() => {
    fetchedPagesRef.current = new Set<number>();
    setProducts([]);
    fetchProducts(0);
  }, [
    searchText,
    selectedMerchants,
    selectedMarketplaces,
    selectedStatuses,
    organization,
    merchants,
    selectedIntegrationNames,
  ]);

  useEffect(() => {
    fetchBundlingProducts();
  }, [bundleSearchText]);

  useEffect(() => {
    if (organization) {
      const fetchMerchants = async () => {
        try {
          const merchantsResponse = await fetchMerchantsData({
            queryParams: { 'filter[organizationId]': organization.organizationId },
            urlParams: {},
          });

          if (merchantsResponse.success) {
            setMerchants(merchantsResponse.data);
          }
        } catch (error) {
          console.error('Error while fetching merchants data:', error);
        }
      };

      fetchMerchants();
    }
  }, [organization]);

  const bundleItems = async () => {
    setLoading(true);

    try {
      const bundlingResponse = await setBundleItems(
        { urlParams: {}, queryParams: {} },
        {
          method: 'POST',
          body: JSON.stringify({
            listingId: bundleItem?.listingId,
            bundleItems: displayProducts.map((p) => {
              if (p?.type === 'amazon') {
                return {
                  integrationConnectionId: integration?.connections[0]?.connectionId,
                  integrationId: integration?.integrationId,
                  marketplaceCatalogId: p.product.marketplaceData.asin,
                  quantity: p.quantity,
                };
              } else {
                return {
                  listingId: p.product.listingId,
                  quantity: p.quantity,
                };
              }
            }),
          }),
        },
      );

      if (bundlingResponse.success) {
        setBundleSlideoverOpen(false);
        setBundlingProducts([]);
        fetchProducts(0);
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Product bundled successfully!',
        });
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: bundlingResponse.message || bundlingResponse.errors[0]?.message,
        });
      }
    } catch (error) {
      console.error('Error while bundling products', error);
    } finally {
      setLoading(false);
    }
  };

  const searchMarketplaceCatalog = async () => {
    setLoading(true);

    try {
      const marketplaceCatalogResponse = await setSearchCatalog(
        { urlParams: {}, queryParams: {} },
        {
          method: 'POST',
          body: JSON.stringify({
            integrationConnectionId: integration?.connections[0]?.connectionId,
            integrationId: integration?.integrationId,
            marketplaceCatalogId: asin,
            marketplaceIdType: 'ASIN',
          }),
        },
      );

      setAsin('');
      setBundleSlideoverOpen(true);

      if (marketplaceCatalogResponse.success) {
        if (marketplaceCatalogResponse.data) {
          setSelectedProducts(
            selectedProducts.concat({ ...marketplaceCatalogResponse.data, type: 'amazon' }),
          );
          setDisplayProducts([
            ...displayProducts,
            { product: marketplaceCatalogResponse.data, quantity: 1, type: 'amazon' },
          ]);
        } else {
          setAmazonSelect(false);
          setShowNotification({
            show: true,
            type: 'failure',
            content: 'Unable to find product on Amazon Catalog!',
          });
        }
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content:
            marketplaceCatalogResponse.message || marketplaceCatalogResponse.errors[0]?.message,
        });
      }
    } catch (error) {
      console.error('Error while searching catalog:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateFnSKULabel = async (listing) => {
    setLoading(true);
    try {
      if (listing.marketplaceData)
        await authenticatedDownload(
          `fnsku-${listing.marketplaceData.sellerSku}`,
          `/api/v1/listings/label`,
          {
            method: 'POST',
            body: JSON.stringify({
              listingId: listing.listingId,
              height: 1.25,
              width: 2.25,
            }),
          },
        );
    } catch (e) {
      console.error(e);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Failed to download the file',
      });
    }
    setLoading(false);
  };

  const loadMoreOrders = () => {
    if (!productsLoading && hasMore) {
      fetchProducts(pageRef.current);
    }
  };

  const toggleProductSelection = (listingId: string) => {
    setSelectedProductIds((prevSelected) =>
      prevSelected.includes(listingId)
        ? prevSelected.filter((id) => id !== listingId)
        : [...prevSelected, listingId],
    );
  };

  const toggleSelectAll = () => {
    if (selectedProductIds.length === products.length) {
      setSelectedProductIds([]); // Deselect all
    } else {
      setSelectedProductIds(products.map((product) => product.listingId)); // Select all
    }
  };

  const handleDownloadSelected = async () => {
    setLoading(true);
    try {
      const selectedProducts = products.filter((product) =>
        selectedProductIds.includes(product.listingId),
      );

      const limit = pLimit(5);

      const downloadPromises = selectedProducts.map((product) =>
        limit(async () => {
          try {
            await generateFnSKULabel(product);
          } catch (error) {
            console.error(`Failed to generate FNSKU for product: ${product.listingId}`, error);
            throw error;
          }
        }),
      );

      await Promise.all(downloadPromises);

      setShowNotification({
        show: true,
        type: 'success',
        content: 'Downloaded FNSKU labels successfully',
      });
    } catch (error) {
      console.error(error);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Failed to download some files',
      });
    }
    setLoading(false);
  };

  const formatAmazonError = (rawMessage: string): string => {
    if (!rawMessage?.includes('Amazon Error')) {
      return rawMessage;
    }

    const match = rawMessage.match(/ERROR: (.*?)(?=\.)/);
    if (!match) {
      return rawMessage;
    }

    const errorText = match[1];
    const prepCategoryMatch = errorText.match(/prepCategory=([^,\s)]+)/);
    const prepTypesMatch = errorText.match(/prepTypes=\[(.*?)\]/);

    const prepCategory = prepCategoryMatch ? prepCategoryMatch[1] : null;
    const prepTypes = prepTypesMatch ? prepTypesMatch[1].split(',') : [];

    if (prepCategory && prepTypes.length > 0) {
      return `Invalid combination: Prep Category "${prepCategory}" cannot be used with Prep Type${
        prepTypes.length > 1 ? 's' : ''
      } "${prepTypes.join(', ')}"`;
    }

    return rawMessage;
  };

  const handleUpdatePrepInstructions = async (listingId: string, updateData: any) => {
    try {
      const response = await updateListingRequest(
        {
          urlParams: { listingId },
          queryParams: {},
        },
        {
          method: 'PUT',
          body: JSON.stringify(updateData),
        },
      );

      if (response.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Prep instructions updated successfully!',
        });

        setProducts(
          products.map((product) =>
            product.listingId === listingId ? { ...product, ...updateData } : product,
          ),
        );
      } else {
        setShowNotification({
          show: true,
          type: 'failure',
          content: formatAmazonError(response.message),
          title: 'Invalid Prep Instructions',
          duration: 10000,
        });
      }
    } catch (error) {
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Failed to update prep instructions. Please try again.',
        title: 'Update Failed',
        duration: 10000,
      });
    }
  };

  return (
    <div className='p-8 max-h-screen flex flex-col'>
      <div className='min-h-[8rem]'>
        <div className='flex justify-between my-2'>
          <div>
            <h1 className='text-[#030229] text-[24px] font-bold'>Products</h1>
            <p className='text-[14px]'>View your clients’ products</p>
          </div>
          {/* <button className='bg-hopstack-blue-700 h-fit flex gap-2 rounded-lg text-white py-2 px-4 hover:opacity-80'>
          <ArrowsUpDownIcon className='w-4 my-auto' />
          <div>Sync Products</div>
        </button> */}
        </div>

        <div className='flex gap-4 my-4'>
          {merchants.length > 0 && (
            <ProductFilter
              merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
              marketplaces={marketplaces as any}
              statuses={statuses.map((e) => ({ label: e, value: e }))}
              onApply={(
                checkedMerchants: any,
                checkedStatus,
                checkedMarketplaces,
                checkedSources,
              ) => {
                setSelectedMerchants(checkedMerchants);
                setSelectedStatuses(checkedStatus);
                setSelectedMarketplaces(checkedMarketplaces);
                setSelectedIntegrationNames(checkedSources);
              }}
              integrationNames={integrationSources.map((e) => ({ label: e, value: e }))}
              onReset={() => {}}
            />
          )}
          <div className='w-full'>
            <label htmlFor='search' className='sr-only'>
              Search
            </label>
            <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
              </div>
              <input
                id='search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                placeholder='Search'
                type='search'
                name='search'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='flex-grow'>
        {selectedProductIds.length > 0 && (
          <div className='flex items-center justify-between mt-4 mb-2'>
            <div className='text-sm font-semibold'>Selected Items: {selectedProductIds.length}</div>
            {allSelectedAreAmazon && (
              <button
                className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
                onClick={handleDownloadSelected}
                disabled={loading}
              >
                {loading ? 'Downloading...' : 'Download FNSKU'}
              </button>
            )}
          </div>
        )}
        <div className='shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <ProductTable
            handleProductClick={() => {}}
            isFetching={productsLoading}
            fetchMoreData={loadMoreOrders}
            hasMore={hasMore}
            products={products}
            generateFnSKULabel={generateFnSKULabel}
            setBundleItem={setBundleItem}
            setDisplayProducts={setDisplayProducts}
            setSelectedProducts={setSelectedProducts}
            toggleProductSelection={toggleProductSelection}
            toggleSelectAll={toggleSelectAll}
            selectedProductIds={selectedProductIds}
            setShowNotification={setShowNotification}
            updateListing={handleUpdatePrepInstructions}
          />
        </div>
      </div>

      <SlideOver
        open={bundleItem !== null}
        setOpen={(open) => setBundleItem(open ? bundleItem : null)}
        large
      >
        <div>
          <label className='block text-[15px] font-medium text-left leading-6 text-gray-900'>
            Product Details
          </label>

          <div className='flex gap-2 my-2'>
            <img src={bundleItem?.images && bundleItem?.images[0]} height={100} width={100} />
            <div className='w-[400px] truncate my-auto'>{bundleItem?.name}</div>
          </div>
          <p className='text-[12px] text-gray-500 mt-4'>
            Select the products to be bundled with this product
          </p>

          <div className='sm:col-span-4 mt-2'>
            <Listbox
              as={'div'}
              value={selectedProducts}
              onChange={(e) => {
                setSelectedProducts(e);

                const existingProducts = selectedProducts.map((p) => p.listingId);
                const updatedProducts = e.map((p) => p.listingId);

                const newProducts = e.filter((prod) => !existingProducts.includes(prod.listingId));
                const deletedProducts = existingProducts.filter(
                  (e) => !updatedProducts.includes(e),
                );
                setDisplayProducts(
                  displayProducts
                    .concat(
                      newProducts?.map((n) => {
                        return { product: n, quantity: 1 };
                      }),
                    )
                    .filter((d) => !deletedProducts.includes(d.product.listingId)),
                );
              }}
              multiple
              className={'relative'}
            >
              <Listbox.Label className='block text-sm font-medium leading-6 text-gray-900'>
                Products
              </Listbox.Label>
              <Listbox.Button
                className={
                  'block w-full px-2 text-left rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
                }
              >
                {selectedProducts.length > 0
                  ? `${selectedProducts.length} products selected`
                  : 'Select Products'}
              </Listbox.Button>
              <Listbox.Options className='absolute mt-2 z-10 w-full max-h-80 overflow-scroll bg-white  border-[1px] shadow-md rounded-md'>
                <div className='relative text-gray-400  shadow-sm w-full rounded-md'>
                  <div className='pointer-events-none absolute left-0 flex items-center pl-3'>
                    <MagnifyingGlassIcon className='h-5 w-5 my-2' aria-hidden='true' />
                  </div>
                  <input
                    id='search'
                    value={bundleSearchText}
                    onChange={(e) => setBundleSearchText(e.target.value)}
                    className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6'
                    placeholder='Search'
                    type='search'
                    name='search'
                  />
                </div>
                {bundlingProducts.map((product, index) => (
                  <Listbox.Option
                    className={`${
                      selectedProducts.find((w) => w.listingId === product.listingId) !== undefined
                        ? 'bg-green-50'
                        : 'bg-white hover:bg-gray-50'
                    } p-2 border truncate`}
                    key={index}
                    value={product}
                  >
                    <div className='flex gap-2'>
                      <img src={product?.images[0]} height={40} width={40} />
                      <div className='line-clamp-3'>{product.name}</div>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>

          <div className='flex gap-2 mt-2'>
            {amazonSelect ? (
              <div>
                <label className='block text-[15px] font-medium text-left leading-6 mt-4 text-gray-900'>
                  ASIN
                </label>
                <p className='text-[12px] text-gray-500'>Enter the asin for the product</p>
                <div className='flex gap-2'>
                  <input
                    type='text'
                    value={asin}
                    onChange={(e) => setAsin(e.target.value)}
                    className='block mt-1 w-[500px] px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
                  />
                  <button
                    onClick={() => searchMarketplaceCatalog()}
                    className='text-blue-500 hover:opacity-80 '
                  >
                    Search
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div>Cannot see product here?</div>
                <button
                  onClick={() => setAmazonSelect(true)}
                  className='text-blue-500 hover:opacity-80'
                >
                  Add from Amazon Catalog
                </button>
              </>
            )}
          </div>
          <div className='overflow-hidden mt-4 shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>
                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Image
                </td>
                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Product
                </td>
                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Quantity
                </td>
                <td className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                  Action
                </td>
              </thead>
              <tbody>
                {displayProducts.map((p, index) => {
                  return (
                    <tr className=' py-4 mt-2' key={index}>
                      <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        <img
                          className='object-scale-down h-24'
                          src={p.product?.images && p.product?.images[0]}
                        />
                      </td>
                      <td className='py-4 pl-4 pr-3 text-sm line-clamp-3 font-medium text-gray-900 sm:pl-6'>
                        {p.product?.name}
                        <br />
                        <a
                          href={`${
                            getMarketplace(p.product.marketplaceData?.marketplaceId).marketplaceUrl
                          }/dp/${p.product.marketplaceData?.asin}`}
                          target='blank'
                          className='hover:underline'
                        >
                          <b>{'ASIN: ' + p.product.marketplaceData?.asin}</b>
                        </a>
                      </td>
                      <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                        <div className='flex gap-2 max-w-[100px]'>
                          <button
                            onClick={() => {
                              setDisplayProducts(
                                displayProducts.map((p, pIndex) => {
                                  if (pIndex === index)
                                    return {
                                      ...p,
                                      quantity: Math.max(1, parseInt(p.quantity) - 1),
                                    };
                                  return p;
                                }),
                              );
                            }}
                          >
                            <MinusIcon className='w-4 h-4 text-hopstack-blue-700' />
                          </button>
                          <input
                            onKeyDown={(evt) =>
                              (evt.keyCode === 190 || evt.keyCode === 189) && evt.preventDefault()
                            }
                            onChange={(e) =>
                              setDisplayProducts(
                                displayProducts.map((p, pIndex) => {
                                  if (pIndex === index) return { ...p, quantity: e.target.value };
                                  return p;
                                }),
                              )
                            }
                            type='number'
                            className='w-[100px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                            value={p.quantity}
                          />
                          <button
                            onClick={() => {
                              setDisplayProducts(
                                displayProducts.map((p, pIndex) => {
                                  if (pIndex === index)
                                    return { ...p, quantity: parseInt(p.quantity) + 1 };
                                  return p;
                                }),
                              );
                            }}
                          >
                            <PlusIcon className='w-4 h-4 text-hopstack-blue-700' />
                          </button>
                        </div>
                      </td>
                      <td className='px-6 m-auto'>
                        <button
                          onClick={() => {
                            setDisplayProducts(
                              displayProducts.filter(
                                (s) =>
                                  displayProducts[index].product.listingId !== s.product.listingId,
                              ),
                            );
                            setSelectedProducts(
                              selectedProducts.filter(
                                (s) => displayProducts[index].product.listingId !== s.listingId,
                              ),
                            );
                          }}
                        >
                          <ArchiveBoxXMarkIcon className='w-8 h-8 text-gray-600 hover:text-red-600' />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className='flex gap-2 mt-8'>
            <button
              onClick={() => setBundleSlideoverOpen(false)}
              className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              <ArrowLeftIcon className='w-4 h-4 my-auto' />
              Go Back
            </button>
            <button
              disabled={selectedProducts.length === 0}
              onClick={() => {
                bundleItems();
              }}
              className='bg-[#224E73] flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
            >
              <div className=''>Save as bundle</div>
            </button>
          </div>
        </div>
      </SlideOver>
    </div>
  );
};

export default Products;
