import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import ModalV2 from '../common/ModalV2';
import Dropdown from '../common/Dropdown';
import MultiDropdown from '../common/MultiDropdown';
import { PrepCategoryEnum, PrepTypeEnum } from '../common/types';

import { getMarketplace } from '../../utils/ConnectionConfig';

export enum ListingStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
}

type Product = {
  images?: string[];
  name?: string;
  status?: string;
  marketplaceData?: {
    sellerSku?: string;
    asin?: string;
    fnSku?: string;
    perishable?: boolean;
  };
  integrationName?: string;
  merchant?: {
    name?: string;
  };
  isBundle?: boolean;
  bundleItems?: any[];
  integration?: {
    connections: any[];
  };
  integrationConnectionId?: string;
  prepCategory?: PrepCategoryEnum;
  prepTypes?: PrepTypeEnum[];
  prepInstructions?: {
    prepCategory?: PrepCategoryEnum;
    prepTypes?: PrepTypeEnum[];
  };
  [key: string]: any;
};

interface ProductTableProps {
  products: Product[];
  handleProductClick: (product: Product) => void;
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
  generateFnSKULabel: (product: Product) => void;
  setBundleItem: React.Dispatch<React.SetStateAction<Product>>;
  setDisplayProducts: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedProducts: React.Dispatch<React.SetStateAction<any[]>>;
  toggleProductSelection: (listingId: string) => void;
  toggleSelectAll: () => void;
  selectedProductIds: string[];
  updateListing: (listingId: string, data: any) => Promise<void>;
  setShowNotification: any;
}

const getStatusColor = (status: ListingStatus) => {
  switch (status) {
    case ListingStatus.ACTIVE:
      return 'text-green-700 bg-green-100';
    case ListingStatus.INACTIVE:
      return 'text-gray-700 bg-gray-100';
    case ListingStatus.ARCHIVED:
      return 'text-red-700 bg-red-100';
    default:
      return '';
  }
};

const ProductTable: React.FC<ProductTableProps> = ({
  products,
  handleProductClick,
  fetchMoreData,
  hasMore,
  isFetching,
  generateFnSKULabel,
  setBundleItem,
  setDisplayProducts,
  setSelectedProducts,
  toggleProductSelection,
  toggleSelectAll,
  selectedProductIds,
  updateListing,
  setShowNotification,
}) => {
  const [prepInstructionsOpen, setPrepInstructionsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedPrepCategory, setSelectedPrepCategory] = useState<PrepCategoryEnum | null>(null);
  const [selectedPrepTypes, setSelectedPrepTypes] = useState<PrepTypeEnum[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handlePrepInstructions = (product: Product) => {
    setSelectedProduct(product);
    setSelectedPrepCategory(product.prepInstructions?.prepCategory || null);
    setSelectedPrepTypes(product.prepInstructions?.prepTypes || []);
    setPrepInstructionsOpen(true);
  };

  const handleSavePrepInstructions = async () => {
    if (selectedProduct && selectedPrepCategory) {
      const hasChanges =
        selectedPrepCategory !== selectedProduct.prepInstructions?.prepCategory ||
        !arraysEqual(selectedPrepTypes, selectedProduct.prepInstructions?.prepTypes || []);

      if (!hasChanges) {
        setShowNotification({
          show: true,
          type: 'info',
          content: 'Please make changes to prep instructions, before saving.',
          title: 'No Changes Made',
          duration: 3000,
        });
        return;
      }

      setIsSaving(true);
      try {
        await updateListing(selectedProduct.listingId, {
          merchantId: selectedProduct.merchantId,
          prepInstructions: {
            prepCategory: selectedPrepCategory,
            prepTypes: selectedPrepTypes,
          },
        });
        setPrepInstructionsOpen(false);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const arraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) return false;
    return a.every((item) => b.includes(item)) && b.every((item) => a.includes(item));
  };

  const columns: Column<Product>[] = [
    {
      key: 'select',
      title: () => (
        <input
          type='checkbox'
          checked={products.length > 0 && selectedProductIds.length === products.length}
          onChange={toggleSelectAll}
        />
      ),
      accessor: (product) => (
        <input
          type='checkbox'
          checked={selectedProductIds.includes(product.listingId)}
          onChange={() => toggleProductSelection(product.listingId)}
        />
      ),
    },
    {
      key: 'image',
      title: 'Image',
      accessor: (product) => (
        <div className='flex items-center justify-center'>
          {product.images && product.images.length > 0 ? (
            <img
              src={product.images[0]}
              alt={product.name}
              className='h-24 w-24 rounded object-scale-down'
            />
          ) : null}
        </div>
      ),
    },
    {
      key: 'name',
      title: 'Name',
      accessor: (product) => (
        <div className='line-clamp-3 text-gray-600 max-w-prose'>
          {product.name}
          {product.isBundle && (
            <div className='bg-purple-200 w-fit px-3 py-1 text-xs rounded text-purple-700'>
              Bundle
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (product) => (
        <div className='relative group w-fit mx-auto'>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full capitalize ${getStatusColor(
              product.status as ListingStatus,
            )}`}
          >
            {product.status}
          </div>
          {product.marketplaceData && !product.marketplaceData.sellerSku && (
            <div className='bg-orange-200 w-fit py-1 px-2 mt-2 text-xs truncate capitalize text-orange-700 rounded-full'>
              Missing Seller SKU
            </div>
          )}
        </div>
      ),
      headerClassnamePerColumn: 'inline-flex justify-center w-full',
    },
    {
      key: 'identifiers',
      title: 'Identifiers',
      accessor: (product) => (
        <div className='max-w-fit'>
          {product.marketplaceData?.asin && (
            <>
              <a
                href={`${
                  getMarketplace(
                    product.integration?.connections.find(
                      (connection: any) =>
                        connection.connectionId === product.integrationConnectionId,
                    )?.details?.marketplaceId,
                  ).marketplaceUrl
                }/dp/${product.marketplaceData.asin}`}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline'
              >
                <span className='font-bold'>ASIN: </span>
                {product.marketplaceData.asin}
              </a>
              {(() => {
                const connection = product.integration?.connections.find(
                  (connection: any) => connection.connectionId === product.integrationConnectionId,
                );

                if (connection?.details?.marketplaceId) {
                  return (
                    <>
                      <span className='font-bold'>MARKETPLACE: </span>
                      {getMarketplace(connection.details?.marketplaceId).countryCode}
                    </>
                  );
                }

                return null;
              })()}
            </>
          )}

          {product.marketplaceData?.fnSku && (
            <>
              <br />
              <span className='font-bold'>FNSKU: </span>
              {product.marketplaceData.fnSku}
              <br />
            </>
          )}
          {product.marketplaceData?.sellerSku && (
            <div className='relative group w-[13rem]'>
              <div className='truncate overflow-hidden'>
                <span className='font-bold'>SKU ID: </span>
                {product.marketplaceData.sellerSku}
              </div>
              <div className='absolute left-0 bottom-full mb-2 hidden w-max max-w-xs rounded bg-white p-2 text-sm shadow-lg group-hover:block'>
                {product.marketplaceData.sellerSku}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'perishable',
      title: 'Perishable',
      accessor: (product) => (product.marketplaceData?.perishable ? 'Yes' : 'No'),
    },
    {
      key: 'source',
      title: 'Source',
      accessor: (product) => product.integrationName,
    },
    {
      key: 'client',
      title: 'Client',
      accessor: (product) => product.merchant?.name,
    },
    {
      key: 'action',
      title: 'Action',
      accessor: (product) => (
        <Menu
          as='div'
          className={`relative overflow-visible ${
            product.marketplaceData?.sellerSku ? '' : 'hidden'
          }`}
        >
          <div>
            <Menu.Button className=''>
              <EllipsisVerticalIcon className='w-8 h-8 px-1' />
            </Menu.Button>
          </div>
          <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div>
              <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                <button onClick={() => generateFnSKULabel(product)} className='flex gap-2 w-full'>
                  <div className='text-[12px]'>Generate FNSKU label</div>
                </button>
              </Menu.Item>
            </div>
            <div>
              <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                <button
                  onClick={() => {
                    setBundleItem(product);
                    if (product?.isBundle) {
                      setDisplayProducts(
                        product?.bundleItems?.map((item) => ({
                          quantity: item.quantity,
                          product: item.listing,
                        })),
                      );
                      setSelectedProducts(product.bundleItems);
                    }
                  }}
                  className='flex gap-2 w-full'
                >
                  <div className='text-[12px]'>
                    {product?.isBundle ? 'Edit bundle' : 'Mark as Bundle'}
                  </div>
                </button>
              </Menu.Item>
            </div>
            {product.integrationName === 'Amazon' && (
              <div>
                <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                  <button
                    onClick={() => handlePrepInstructions(product)}
                    className='flex gap-2 w-full'
                  >
                    <div className='text-[12px]'>Prep Instructions</div>
                  </button>
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Menu>
      ),
    },
  ];

  return (
    <>
      <InfiniteScrollingTable
        onSort={() => {}}
        totalDataCount={products.length}
        columns={columns}
        data={products}
        fetchMoreData={fetchMoreData}
        hasMore={hasMore}
        loadingData={isFetching && products.length === 0}
        onRowClick={handleProductClick}
      />

      <ModalV2
        isOpen={prepInstructionsOpen}
        onClose={() => setPrepInstructionsOpen(false)}
        title={`Prep Instructions: ${selectedProduct?.marketplaceData?.sellerSku}`}
        maxWidth='800px'
        maxHeight='600px'
        isLoading={isSaving}
      >
        <div className='space-y-6 font-inter'>
          <div className='space-y-4'>
            <div className='text-lg'>Prep Category:</div>
            <Dropdown
              items={Object.values(PrepCategoryEnum).map((item) => ({
                id: item,
                value: item,
              }))}
              selectedItem={
                selectedPrepCategory
                  ? {
                      id: selectedPrepCategory,
                      value: selectedPrepCategory,
                    }
                  : null
              }
              setSelectedItem={(item) => setSelectedPrepCategory(item.value as PrepCategoryEnum)}
              getItemId={(item) => item.id}
              getItemDisplayValue={(item) => item.value}
              placeholder='Select prep category'
              className='w-full z-auto'
            />

            <div className='text-lg'>Prep Types:</div>
            <MultiDropdown
              items={Object.values(PrepTypeEnum).map((item) => ({
                id: item,
                value: item,
              }))}
              selectedItems={selectedPrepTypes.map((type) => ({ id: type, value: type }))}
              setSelectedItems={(items) =>
                setSelectedPrepTypes(items.map((item) => item.value as PrepTypeEnum))
              }
              getItemId={(item) => item.id}
              getItemDisplayValue={(item) => item.value}
              placeholder='Select prep types'
              className='w-full z-auto'
            />
          </div>

          <div className='flex justify-end gap-3 mt-8'>
            <button
              type='button'
              className='inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
              onClick={() => setPrepInstructionsOpen(false)}
              disabled={isSaving}
            >
              Cancel
            </button>
            <button
              type='button'
              className='inline-flex justify-center rounded-md bg-hopstack-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-500 disabled:opacity-50'
              onClick={handleSavePrepInstructions}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className='flex items-center gap-2'>
                  <div className='w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin' />
                  Saving...
                </div>
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </ModalV2>
    </>
  );
};

export default ProductTable;
