import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks/useSelector';
import { useAppDispatch } from '../redux/hooks/useDispatch';
import useRequest from '../hooks/useRequest';
import { RequestParams } from '../hooks/useRequest';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import BaseContext from '../components/common/BaseContext';
import { apiPaths } from '../utils/ApiPaths';
import {
  Product,
  Warehouse,
  FbaPackingTabsEnums,
  FbaPackingLtlFlowTabsEnums,
  PrepCategoryEnum,
  PrepTypeEnum,
} from '../components/common/types';
import { getMarketplace } from '../utils/ConnectionConfig';
import { Integration, IntegrationName } from './ConnectionDetails';
import Button from '../components/common/Button';
import ModalV2 from '../components/common/ModalV2';
import SlideOver from '../components/common/SlideOver';
import SelectPackingOptions from './SelectPackingOptions';
import CustomTabSelection from '../components/common/CustomTabSelection';
import {
  startAmazonInboundPlanCreation,
  getPackingOptions,
  cancelAmazonInboundPlan,
  confirmPackingOptions,
  setPackingInformation,
  getPlacementsOptions,
  getTransportationOptions,
  confirmTransportationOptions,
  confirmPlacementOption,
  getDeliveryWindowOptions,
  getErrorsInInboundPlan,
  confirmDeliveryWindowOptions,
  listShipments,
  confirmPackingCompleted,
} from '../redux/FbaPacking/fbaPackingActions';
import { useAuth } from '@clerk/clerk-react';
import { resetFbaPackingState } from '../redux/FbaPacking/fbaPackingSlice';
import ConfirmationModal from '../components/common/ConfirmationModal';
import PackingBoxInformation from './PackingBoxInformation';
import SelectPlacementOptions from './SelectPlacementOptions';
import FbaRateShopping from './FbaRateShopping';
import SelectDeliveryOptions from './SelectDeliveryOption';
import FbaShipmentDetails from './FbaShipmentDetails';
import HeaderWithArrow from '../components/common/HeaderWithArrow';
import CustomPopover from '../components/common/CustomPopover';
import { OutBoundOrderStatus } from '../components/Outbound/OrdersTable';
import { Menu } from '@headlessui/react';
import pLimit from 'p-limit';
import useDownload from '../hooks/useDownload';
import Dropdown from '../components/common/Dropdown';
import MultiDropdown from '../components/common/MultiDropdown';
import { Tooltip } from 'react-tooltip';

export const fulfillmentDetailNavigation = [
  { name: 'Box' },
  { name: 'Pallet' },
  { name: 'Rate Shopping' },
  { name: 'Product Selection' },
];

export const newBox = {
  weight: null,
  height: null,
  width: null,
  length: null,
  weightUnit: 'pounds',
  dimensionUnit: 'inches',
};

export interface Order {
  outboundOrderId: string;
  merchantId: string;
  integrationId: string;
  integrationConnectionId: string;
  status: string;
  lineItems: {
    listingId: string;
    quantity: number;
    _id: string;
    listing: Product;
    prepOwner: string;
    prepDetails: any;
    labelOwner: string;
    selectedExpiry: string;
  }[];
  marketplaceData: {
    orderId: string;
    orderDisplayId: string;
    shopifyGraphQlId: string;
    displayFinancialStatus: string;
  };
  marketplaceError: any;
  isDeleted: boolean;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  merchant: {
    name: string;
    merchantId: string;
    organizationId: string;
    email: string;
    status: string;
    isDeleted: boolean;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  };
  warehouse?: Warehouse;
  integration?: Integration;
  shipmentPlanDetails?: any;
  version?: string;
  warehouseId?: string;
}

/**
 * Formats an address into a string.
 * @param address The address to format.
 * @returns The formatted address string.
 */
export function formatAddress(address: any): string {
  if (!address || Object.keys(address).length === 0) {
    return 'N/A';
  }

  let formattedAddress: string[] = [];

  // Handle the first format
  if (address.Name) {
    formattedAddress = [
      address.Name,
      address.AddressLine1,
      `${address.City}, ${address.StateOrProvinceCode} ${address.PostalCode}`,
      address.CountryCode,
    ];
  }
  // Handle the second format
  else {
    formattedAddress = [
      address.name,
      address.line1,
      address.line2,
      address.line3,
      `${address.city || ''}, ${address.state || ''} ${address.zipCode || ''}${
        address.zipCodeSuffix ? `-${address.zipCodeSuffix}` : ''
      }`,
      address.country.name || address.country,
    ];
  }

  return formattedAddress.filter((line) => line).join('\n');
}
const POLLING_INTERVAL = 5000;

interface ConfirmationConfig {
  title: string;
  content: React.ReactNode;
  onConfirm: () => void;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
}

// Type to represent which enum we're currently using
type TabsEnum = typeof FbaPackingTabsEnums | typeof FbaPackingLtlFlowTabsEnums;

const hasUnknownPrepCategory = (order: any) => {
  return order?.lineItems?.some((item: any) => item?.prepDetails?.prepCategory === 'UNKNOWN');
};

const getUnknownPrepItemsInfo = (order: any) => {
  const unknownItems = order?.lineItems?.filter(
    (item: any) => item?.prepDetails?.prepCategory === 'UNKNOWN',
  );

  return unknownItems?.map((item: any) => item?.listing?.marketplaceData?.asin)?.join(', ');
};

const handleBulkUpdateUnknownPrep = async (
  order: any,
  updateOutboundOrder: any,
  setShowNotification: any,
  setLoading: any,
  fetchOrder: () => Promise<void>,
) => {
  try {
    setLoading(true);

    const updatedLineItems = order.lineItems.map((lineItem) => ({
      listingId: lineItem.listing.listingId,
      quantity: lineItem.quantity,
      prepDetails:
        lineItem.prepDetails?.prepCategory === 'UNKNOWN'
          ? {
              ...lineItem.prepDetails,
              prepCategory: 'NONE',
              prepTypes: ['ITEM_NO_PREP'],
            }
          : lineItem.prepDetails,
      isDeleted: false,
    }));

    const response = await updateOutboundOrder(
      {
        urlParams: { outboundOrderId: order.outboundOrderId },
        queryParams: {},
      },
      {
        method: 'PATCH',
        body: JSON.stringify({
          lineItems: updatedLineItems,
          warehouseId: order.warehouseId,
          version: order.version,
        }),
      },
    );

    if (response.success) {
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Successfully updated unknown prep categories',
      });
      await fetchOrder();
    } else {
      throw new Error(response.message || 'Failed to update prep categories');
    }
  } catch (error) {
    console.error('Error updating prep categories:', error);
    setShowNotification({
      show: true,
      type: 'error',
      content: error.message || 'Failed to update prep categories. Please try again.',
    });
  } finally {
    setLoading(false);
  }
};

const OutboundDetail = () => {
  const { getToken } = useAuth();
  const {
    setLoading,
    organization,
    setShowNotification,
    setOrganization,
    setLoadingOverlay,
    removeLoadingOverlay,
  } = useContext(BaseContext);

  const dispatch = useAppDispatch();
  const authenticatedDownload = useDownload();
  const intervalRefs = useRef<number[]>([]); // Store all active interval IDs

  const [fbaTabs, setFbaTabs] = useState<TabsEnum>(FbaPackingTabsEnums);
  const [activeTab, setActiveTab] = useState<FbaPackingTabsEnums | FbaPackingLtlFlowTabsEnums>(
    FbaPackingTabsEnums.PACKING_OPTIONS,
  );

  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [showBoxDetailsModal, setShowBoxDetailsModal] = useState(false);
  const [selectedPackingOption, setSelectedPackingOption] = useState(null);
  const [formattedGroups, setFormattedGroups] = useState([]);

  const [shipmentData, setShipmentData] = useState([]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationConfig | null>(null);
  const [selectedPlacementOption, setSelectedPlacementOption] = useState(null);
  const [selectedTransportationOption, setSelectedTransportationOption] = useState({});
  const [selectedDeliveryWindow, setSelectedDeliveryWindow] = useState([]);
  const [transportationRates, setTransportationRates] = useState([]);
  const [shouldConfirmTransportation, setShouldConfirmTransportation] = useState(false);

  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedRows, setModifiedRows] = useState(new Set());
  const [editedLineItems, setEditedLineItems] = useState({});

  const urlParams = useParams();
  const navigate = useNavigate();

  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { executeRequest: fetchOrderData, data: order } = useRequest<Order>(
    `${apiPaths.GET_OUTBOUND_ORDER}`,
  );
  const { executeRequest: createShopifyFulfillment } = useRequest(
    '/api/v1/outbound-orders/{outboundOrderId}/fulfillment/shopify',
    {},
  );
  const { executeRequest: updateFulfillmentRequest } = useRequest(
    `/api/v1/fulfillments/shipment/{shipmentId}`,
    {},
  );
  const { executeRequest: updateOrderStatus } = useRequest(
    `/api/v1/outbound-orders/{outboundOrderId}/status`,
    {},
  );

  const { executeRequest: updateOutboundOrder } = useRequest(
    `/api/v1/outbound-orders/{outboundOrderId}`,
    {},
  );

  const {
    packingOptionsResult,
    setPackingInfoResult: packingInformationResult,
    placementsResult: getPlacementOptionsResult,
    getDeliverWindowOptionsResult,
    transportationResult: getTransportationsOptionsResult,
    shipmentsResult: listShipmentsResult,
  } = useAppSelector((state: any) => state.fbaPacking);

  const packingOptions =
    fbaTabs === FbaPackingLtlFlowTabsEnums
      ? getPlacementOptionsResult?.data?.placementOptionsWithShipmentBoxesAndItems || []
      : fbaTabs === FbaPackingTabsEnums
        ? packingOptionsResult?.data?.packingOptionsWithGroupItems || []
        : [];

  const placementOptionForLtl =
    selectedPlacementOption &&
    getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems?.length
      ? getPlacementOptionsResult?.data?.placementOptionsWithShipmentBoxesAndItems?.find(
          (option) => {
            return option.placementOptionId === selectedPlacementOption;
          },
        )
      : JSON.parse(localStorage.getItem('selectedPlacementOptionData'));

  useEffect(() => {
    try {
      setLoading(true);
      if (urlParams.orgId && !organization) {
        (async () => {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          const organizationsResponse = await fetchOrganizationsData(organizationParams);
          if (organizationsResponse.success && organizationsResponse.data) {
            const org = organizationsResponse.data.find(
              (org) => org.organizationId === urlParams.orgId,
            );
            setOrganization(org);
            if (!org) location.replace('/');
          } else location.replace('/');
        })();
      }
    } catch (error) {
      console.log('Error fetching organizations data', error);
      location.replace('/');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (urlParams.orderId) {
      fetchOrder();
    }
  }, [urlParams.orderId]);

  useEffect(() => {
    const inboundDetails = order?.shipmentPlanDetails;
    if (!inboundDetails?.amazonInboundPlanId) return;
    if (
      order?.status === OutBoundOrderStatus.PAUSED ||
      order?.status === OutBoundOrderStatus.COMPLETED ||
      order?.status === OutBoundOrderStatus.CANCELLED
    )
      return;
    handleMoreActions();
    if (validateLtlFlow(inboundDetails)) {
      setFbaTabs(FbaPackingLtlFlowTabsEnums);
    } else {
      setFbaTabs(FbaPackingTabsEnums);
    }

    const labelGenerationReady = hasLabelGenerationSetup(inboundDetails);

    if (labelGenerationReady) {
      setActiveTab(FbaPackingTabsEnums.LABEL_GENERATION);
      pollShipmentLists();
      setSlideOverOpen(true);
      return;
    }

    let hasDeliveryWindowSetup;

    if (inboundDetails?.selectedTransportationOption?.[0]?.transportationOptionId) {
      const areCarriersPartnered = inboundDetails?.selectedTransportationOption?.every((option) => {
        return option.shippingSolution === 'AMAZON_PARTNERED_CARRIER';
      });
      hasDeliveryWindowSetup = areCarriersPartnered === false;
    } else {
      hasDeliveryWindowSetup = false;
    }

    if (hasDeliveryWindowSetup) {
      setActiveTab(FbaPackingTabsEnums.DELIVERY_WINDOW);
      pollDeliveryWindowOptions();
      setSelectedPlacementOption(inboundDetails?.selectedPlacementOption?.placementOptionId);
      setSlideOverOpen(true);
      return;
    }

    const hasBoxInformationSetupForLtl =
      inboundDetails?.packageGroupings?.length > 0 && validateLtlFlow(inboundDetails);

    if (hasBoxInformationSetupForLtl) {
      setActiveTab(FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS);
      setSlideOverOpen(true);
      return;
    }

    const hasPlacementOptionSelectedForLtl =
      inboundDetails?.selectedPlacementOption?.placementOptionId && validateLtlFlow(inboundDetails);

    if (hasPlacementOptionSelectedForLtl) {
      setActiveTab(FbaPackingLtlFlowTabsEnums.BOX_INFORMATION);
      setSelectedPlacementOption(inboundDetails?.selectedPlacementOption?.placementOptionId);
      pollPlacementOptions(false, true);
      setSlideOverOpen(true);
      return;
    }

    const hasPackingOrPlacement =
      inboundDetails?.packageGroupings?.length > 0 || inboundDetails?.placementOptions?.length > 0;

    if (hasPackingOrPlacement) {
      setActiveTab(FbaPackingTabsEnums.PLACEMENT_OPTIONS);
      const shouldGenerateTransportationOptions = validateLtlFlow(inboundDetails) === false;
      pollPlacementOptions(shouldGenerateTransportationOptions);
      setSlideOverOpen(true);
      return;
    }

    const handlePackingOptionsSuccess = (data: any) => {
      setLoading(false);

      if (data?.errors?.length) {
        setShowNotification({
          show: true,
          type: 'error',
          content: data.errors[0].message,
        });
        return;
      }

      if (!data?.packingOptionsWithGroupItems?.length) {
        setShowNotification({
          show: true,
          type: 'error',
          content: 'Failed to fetch packing options',
        });
        return;
      }

      if (inboundDetails?.selectedPackingOption?.packingOptionId) {
        setActiveTab(FbaPackingTabsEnums.BOX_INFORMATION);
        setSelectedPackingOption(inboundDetails?.selectedPackingOption?.packingOptionId);
      } else {
        setSelectedPackingOption(data.packingOptionsWithGroupItems[0].packingOptionId);
      }
      setSlideOverOpen(true);
    };

    const handlePackingOptionsError = (error: any) => {
      setLoading(false);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to fetch packing options',
      });
    };
    if (inboundDetails?.selectedPackingOption?.packingOptionId) {
      setLoading(true);
      dispatch(
        getPackingOptions({
          variables: {
            input: {
              outboundOrderId: order?.outboundOrderId,
            },
          },
          successCallback: handlePackingOptionsSuccess,
          errorCallback: handlePackingOptionsError,
          getToken,
          method: 'GET',
        }),
      );
    } else if (inboundDetails?.amazonInboundPlanId && !validateLtlFlow(inboundDetails)) {
      pollPackingOptions();
    } else if (inboundDetails?.amazonInboundPlanId && validateLtlFlow(inboundDetails)) {
      setActiveTab(FbaPackingLtlFlowTabsEnums.PLACEMENT_OPTIONS);
      setSlideOverOpen(true);
      pollPlacementOptions(false);
    }
    setLoading(false);
  }, [order]);

  useEffect(() => {
    if (packingInformationResult.isPending) setLoading(true);
    else setLoading(false);
  }, [packingInformationResult]);

  useEffect(() => {
    if (shouldConfirmTransportation && Object.keys(selectedTransportationOption).length > 0) {
      handleConfirmTransportationOptions(placementOptionForLtl.shipmentIds, false);
      setShouldConfirmTransportation(false); // Reset the flag
    }
  }, [selectedTransportationOption, shouldConfirmTransportation]);

  useEffect(() => {
    dispatch(resetFbaPackingState());
    clearAllIntervals(); // Clear all intervals when the component is unmounted
  }, []);

  useEffect(() => {
    if (
      getTransportationsOptionsResult?.data?.transportationOptionsPerShipment &&
      isLTLWorkflow()
    ) {
      const newOptions = getTransportationsOptionsResult.data.transportationOptionsPerShipment;

      setTransportationRates((prev) => {
        const updatedOptions = [...prev];

        newOptions.forEach((newOption) => {
          const existingIndex = updatedOptions.findIndex(
            (option) => option.shipmentId === newOption.shipmentId,
          );

          if (existingIndex > -1) {
            // Replace existing option
            updatedOptions[existingIndex] = newOption;
          } else {
            // Add new option
            updatedOptions.push(newOption);
          }
        });

        return updatedOptions;
      });
    }
  }, [getTransportationsOptionsResult]);

  useEffect(() => {
    if (listShipmentsResult?.data?.data) {
      const transformedData = handleTransformShipmentData(listShipmentsResult.data.data);
      setShipmentData(transformedData);
    } else {
      setShipmentData([]);
    }
  }, [listShipmentsResult.data.data]);

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const orderParams: RequestParams = {
        queryParams: { include: 'merchant,listing,integration' },
        urlParams: { orderId: urlParams.orderId },
      };
      await fetchOrderData(orderParams);
    } catch (error) {
      console.log('Error fetching order', error);
    } finally {
      setLoading(false);
    }
  };

  const addInterval = (id) => {
    intervalRefs.current.push(id);
  };

  async function handleShopifyFulfillmentCreation(outboundOrderId: string) {
    try {
      const fulfillmentResponse = await createShopifyFulfillment(
        {
          urlParams: { outboundOrderId },
          queryParams: {},
        },
        {
          method: 'POST',
        },
      );

      if (fulfillmentResponse.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Created fulfillment',
        });
      } else {
        throw new Error(fulfillmentResponse.message);
      }
    } catch (error) {
      console.error('Error creating fulfillment:', error);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to create fulfillment. Please try again.',
      });
    }
  }

  const isLTLWorkflow = () => fbaTabs === FbaPackingLtlFlowTabsEnums;

  const getAvailableTabs = () => Object.values(fbaTabs);

  const clearAllIntervals = () => {
    intervalRefs.current.forEach((id) => clearInterval(id)); // Clear each interval
    intervalRefs.current = []; // Reset the array
  };

  const handleGetConfirmCtaText = () => {
    if (activeTab === FbaPackingTabsEnums.PACKING_OPTIONS) {
      return 'Proceed To Add Box Details';
    } else if (activeTab === FbaPackingTabsEnums.PLACEMENT_OPTIONS) {
      return 'Confirm Placement Option';
    } else if (activeTab === FbaPackingTabsEnums.BOX_INFORMATION) {
      if (isLTLWorkflow()) {
        return 'Proceed to choose Transportation Options';
      } else {
        return 'Proceed to Choose Placement Options';
      }
    } else if (activeTab === FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS) {
      return 'Confirm Transportation Options';
    } else if (activeTab === FbaPackingTabsEnums.DELIVERY_WINDOW) {
      return 'Confirm Delivery Window Options';
    } else if (activeTab === FbaPackingTabsEnums.LABEL_GENERATION) {
      return 'Save';
    } else return 'Confirm';
  };

  const loadingOverlayHtmlContent = (
    <div className='mt-8 flex items-center justify-center p-3'>
      <span className='text-xs'>
        If you want to pause the packing please click{' '}
        <span
          onClick={() => {
            handlePackingStatus('paused');
            removeLoadingOverlay();
            setLoading(false);
            clearAllIntervals();
          }}
          className='cursor-pointer text-xs text-red-500 underline'
        >
          here
        </span>
        .
      </span>
    </div>
  );

  const handleStartAmazonInboundCreation = (shouldGeneratePackingOptions: boolean) => {
    if (order?.outboundOrderId) {
      const amazonInboundCreationVariables = {
        input: {
          outboundOrderId: order.outboundOrderId,
          shouldGeneratePackingOptions,
        },
      };

      const successCallback = (response: any) => {
        if (response?.data?.workflowId) {
          if (shouldGeneratePackingOptions) {
            setShowNotification({
              show: true,
              type: 'success',
              content: 'Started generating Amazon inbound plan \n Polling for packing options...',
            });
            pollPackingOptions();
          } else {
            setShowNotification({
              show: true,
              type: 'success',
              content: 'Started generating Amazon inbound plan \n Polling for placement options...',
            });
            pollPlacementOptions(false);
          }
        } else {
          setShowNotification({
            show: true,
            type: 'error',
            content: 'Failed to start Amazon Inbound Plan Creation - No WorkflowId found',
          });
        }
      };

      const errorCallback = (error: any) => {
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to generate Amazon inbound plan. Please try again.',
        });
        setLoading(false);
      };

      dispatch(
        startAmazonInboundPlanCreation({
          variables: amazonInboundCreationVariables,
          successCallback,
          errorCallback,
          getToken,
        }),
      );
    }
  };

  const pollPackingOptions = () => {
    setLoadingOverlay('Packing options are being Generated.', '', loadingOverlayHtmlContent);
    const intervalId = setInterval(() => {
      const variables = {
        input: {
          outboundOrderId: order?.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.errors?.length) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching packing options',
          });
          removeLoadingOverlay();
          clearInterval(intervalId);
        } else if (response?.packingOptionsWithGroupItems?.length > 0) {
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Packing options generated successfully',
          });
          setSelectedPackingOption(response?.packingOptionsWithGroupItems[0]?.packingOptionId);
          setActiveTab(FbaPackingTabsEnums.PACKING_OPTIONS);
          setSlideOverOpen(true);
          removeLoadingOverlay();
          clearInterval(intervalId);
        } else {
          setLoadingOverlay('Packing options are being Generated.', '', loadingOverlayHtmlContent);
          console.log('Polling for packing options...');
        }
      };

      const errorCallback = (error: any) => {
        console.error('Error polling packing options:', error);
        setShowNotification({
          show: true,
          type: 'error',
          content: 'Failed to fetch packing options',
        });
        clearInterval(intervalId);
        removeLoadingOverlay();
      };

      dispatch(
        getPackingOptions({
          variables,
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const handleCancelInboundPlan = () => {
    setLoadingOverlay('Cancelling inbound plan.', '');
    if (order?.outboundOrderId) {
      const cancelAmazonInboundPlanVariables = {
        input: {
          outboundOrderId: order.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.status === 'SUCCESS') {
          removeLoadingOverlay();
          clearAllIntervals();
          dispatch(resetFbaPackingState());
          setSlideOverOpen(false);
          fetchOrder();
        } else {
          setShowNotification({
            show: true,
            type: 'error',
            content: response?.errors?.[0]?.message || 'Failed to cancel inbound plan',
          });
          removeLoadingOverlay();
        }
      };

      const errorCallback = (error: any) => {
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to cancel inbound plan. Please try again.',
        });
        removeLoadingOverlay();
      };

      dispatch(
        cancelAmazonInboundPlan({
          variables: cancelAmazonInboundPlanVariables,
          successCallback,
          errorCallback,
          getToken,
        }),
      );
    }
  };

  const showConfirmation = (config: ConfirmationConfig) => {
    setConfirmationConfig(config);
    setShowConfirmationModal(true);
  };

  // const allCarriersArePartnered = (transportationOptions, shipmentIds) => {
  //   return shipmentIds.every((shipmentId) => {
  //     const shipmentOptions = transportationOptions.find((opt) => opt.shipmentId === shipmentId);
  //     return shipmentOptions?.transportationOptions.every(
  //       (option) => option.shippingSolution === 'AMAZON_PARTNERED_CARRIER',
  //     );
  //   });
  // };

  const handleConfirmPackingOptions = () => {
    showConfirmation({
      title: 'Confirm Packing Options',
      content: 'Are you sure you want to confirm the selected packing options?',
      onConfirm: () => {
        if (order?.outboundOrderId && selectedPackingOption) {
          const confirmPackingOptionsVariables = {
            input: {
              outboundOrderId: order.outboundOrderId,
              packingOptionId: selectedPackingOption,
            },
          };

          const successCallback = (response: any) => {
            setLoading(false);
            if (response?.status === 'SUCCESS') {
              setShowNotification({
                show: true,
                type: 'success',
                content: 'Packing options confirmed successfully',
              });
              setSlideOverOpen(true);
              setActiveTab(FbaPackingTabsEnums.BOX_INFORMATION);
            } else {
              setShowNotification({
                show: true,
                type: 'error',
                content: response?.errors?.[0]?.message || 'Failed to confirm packing options',
              });
            }
          };

          const errorCallback = (error: any) => {
            setShowNotification({
              show: true,
              type: 'error',
              content: error.message || 'Failed to confirm packing options. Please try again.',
            });
          };

          dispatch(
            confirmPackingOptions({
              variables: confirmPackingOptionsVariables,
              successCallback,
              errorCallback,
              getToken,
            }),
          );
        }
      },
    });
  };

  const handleFormatDataForSetPackingInformation = (formattedGroups: any) => {
    return {
      outboundOrderId: order?.outboundOrderId,
      packageGroupings: formattedGroups.map((group: any) => ({
        ...(fbaTabs === FbaPackingLtlFlowTabsEnums
          ? { shipmentId: group.packingGroupId }
          : { packingGroupId: group.packingGroupId }),
        boxes: group.boxes.map((box: any) => ({
          weight: {
            unit: box.weightUnit?.toUpperCase() || 'LB',
            value: box.weight,
          },
          quantity: 1,
          items: box.items.map((item: any) => ({
            asin: item.asin,
            msku: item.msku,
            fnsku: item.fnsku,
            labelOwner: item.labelOwner || 'SELLER',
            prepOwner: item.prepOwner || 'SELLER',
            quantity: item.quantity,
            expiration: item.expiration?.split('T')?.[0],
          })),
          dimensions: {
            height: parseFloat(box.dimensions?.height),
            length: parseFloat(box.dimensions?.length),
            unitOfMeasurement: box.dimensions?.unitOfMeasurement?.toUpperCase() || 'IN',
            width: parseFloat(box.dimensions?.width),
          },
          contentInformationSource: box.source || group.currentSource,
        })),
      })),
    };
  };

  const handleSetPackingInformation = (formattedGroups: any) => {
    showConfirmation({
      title: 'Confirm',
      content: 'Are you sure you want to set the packing information for this plan?',
      onConfirm: () => {
        if (order?.outboundOrderId) {
          const setPackingInformationVariables = {
            ...handleFormatDataForSetPackingInformation(formattedGroups),
          };
          const successCallback = (response: any) => {
            if (response?.status === 'SUCCESS') {
              setShowNotification({
                show: true,
                type: 'success',
                content: 'Packing information set successfully',
              });

              if (isLTLWorkflow()) {
                setActiveTab(FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS);
              } else {
                setActiveTab(FbaPackingTabsEnums.PLACEMENT_OPTIONS);
                pollPlacementOptions(true);
              }
            } else if (response?.errors) {
              // Format error message by replacing packingGroupId with GroupName
              const errorMessage = response.errors[0].message;
              const formattedError = formattedGroups.reduce((message, group) => {
                return message.replace(new RegExp(group.packingGroupId, 'g'), group.groupName);
              }, errorMessage);
              setShowNotification({
                show: true,
                type: 'error',
                content: formattedError,
              });
            } else {
              setShowNotification({
                show: true,
                type: 'error',
                content: response?.errors?.[0]?.message || 'Failed to set packing information',
              });
            }
          };

          const errorCallback = (error: any) => {
            const formattedError = formattedGroups.reduce((message, group) => {
              return message.replace(new RegExp(group.packingGroupId, 'g'), group.groupName);
            }, error);
            setShowNotification({
              show: true,
              type: 'error',
              content: formattedError || 'Failed to set packing information. Please try again.',
            });
          };

          dispatch(
            setPackingInformation({
              variables: { input: setPackingInformationVariables },
              successCallback,
              errorCallback,
              getToken,
            }),
          );
        }
      },
    });
  };

  const validateLtlFlow = (inboundDetails) => {
    if (
      (!inboundDetails.packingOptions || !inboundDetails.packingOptions.length) &&
      (inboundDetails.placementOptions || inboundDetails.placementOptions.length)
    ) {
      return true;
    }
    return false;
  };

  const pollPlacementOptions = (fetchTransportationOptions: boolean, onBoxInformation = false) => {
    setLoadingOverlay('Placements options are being Generated.', '', loadingOverlayHtmlContent);
    const intervalId = setInterval(() => {
      const variables = {
        input: {
          outboundOrderId: order?.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.errors?.length) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching placement options',
          });
          removeLoadingOverlay();
          clearInterval(intervalId);
        } else if (response?.placementOptionsWithShipmentBoxesAndItems?.length > 0) {
          removeLoadingOverlay();
          clearInterval(intervalId);
          if (!fetchTransportationOptions) {
            if (!onBoxInformation) {
              setFbaTabs(FbaPackingLtlFlowTabsEnums);
              setActiveTab(FbaPackingLtlFlowTabsEnums.PLACEMENT_OPTIONS);
              setSlideOverOpen(true);
              setSelectedPlacementOption(
                response.placementOptionsWithShipmentBoxesAndItems[0].placementOptionId,
              );
            }
          } else {
            setSelectedPlacementOption(
              response.placementOptionsWithShipmentBoxesAndItems[0].placementOptionId,
            );
          }

          const shipmentIdsList = response.placementOptionsWithShipmentBoxesAndItems
            .map((grouping: any) => grouping.shipmentIds)
            .flat()
            .filter(Boolean);

          if (fetchTransportationOptions) {
            pollTransportationOptions(shipmentIdsList.length);
          }
        } else {
          setLoadingOverlay(
            'Placements options are being Generated.',
            '',
            loadingOverlayHtmlContent,
          );
          console.log('Polling for placement options...');
        }
      };

      const errorCallback = (error: any) => {
        console.log(error, 'placement options error');
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to fetch placement options',
        });
        clearInterval(intervalId);
        removeLoadingOverlay();
      };

      dispatch(
        getPlacementsOptions({
          variables,
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const pollTransportationOptions = (shipmentIdsListLength: number, isLtlFlow?: boolean) => {
    if (isLtlFlow) {
      setLoadingOverlay(
        'Transportation options are being Generated.',
        '',
        loadingOverlayHtmlContent,
      );
    }
    const intervalId = setInterval(() => {
      const variables = {
        input: {
          outboundOrderId: order?.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.errors?.length) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching transportation options',
          });
          clearInterval(intervalId);
          removeLoadingOverlay();
        } else if (response?.transportationOptionsPerShipment?.length === shipmentIdsListLength) {
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Fetched transportation options for all shipments successfully',
          });
          clearInterval(intervalId);
          removeLoadingOverlay();
        } else {
          console.log('Polling for transportation options...');
        }
      };

      const errorCallback = (error: any) => {
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to fetch transportation options',
        });
        clearInterval(intervalId);
        removeLoadingOverlay();
      };

      dispatch(
        getTransportationOptions({
          variables,
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const handleConfirmationForPlacementOptions = () => {
    const isLtlFlow = isLTLWorkflow();
    const title = isLtlFlow
      ? 'Confirm Placement Option'
      : 'Confirm Placement and Transportation Options';
    const content = isLtlFlow
      ? 'Are you sure you want to confirm the selected placement option?'
      : 'Are you sure you want to confirm the selected placement and transportation options?';
    showConfirmation({
      title,
      content,
      onConfirm: handleConfirmPlacementOption,
    });
  };

  const handleConfirmPlacementOption = () => {
    const isLtlFlow = isLTLWorkflow();

    // Get the selected placement option
    const selectedPlacementOptionData =
      getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems?.find(
        (option) => option.placementOptionId === selectedPlacementOption,
      );

    if (!selectedPlacementOptionData) {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'Selected placement option not found',
      });
      return;
    }

    // Check if rates are selected for all shipments in the selected placement option
    const allShipmentsHaveRates = selectedPlacementOptionData.shipmentIds.every(
      (shipmentId) => selectedTransportationOption[shipmentId]?.transportationOptionId,
    );

    // const areCarriersPartnered = selectedPlacementOptionData.shipmentIds.every((shipmentId) => {
    //   return selectedTransportationOption[shipmentId]?.isPartnered;
    // });

    if (!allShipmentsHaveRates && !isLtlFlow) {
      setShowNotification({
        show: true,
        type: 'warning',
        content: 'Please select rates for all locations before confirming',
      });
      return;
    }

    const confirmPlacementVariables = {
      input: {
        outboundOrderId: order?.outboundOrderId,
        placementOptionId: selectedPlacementOption,
      },
    };

    if (!isLtlFlow) {
      const areRatesValid = handleConfirmTransportationOptions(
        selectedPlacementOptionData.shipmentIds,
        true,
      );
      if (!areRatesValid) return;
    }

    setLoading(true);

    const successCallback = (data: any) => {
      setLoading(false);
      if (data?.status === 'SUCCESS') {
        localStorage.setItem(
          'selectedPlacementOptionData',
          JSON.stringify(selectedPlacementOptionData),
        );
        removeLoadingOverlay();
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Placement option confirmed successfully',
        });

        clearAllIntervals();

        if (isLtlFlow) {
          setActiveTab(FbaPackingLtlFlowTabsEnums.BOX_INFORMATION);
        } else {
          // if (areCarriersPartnered) {
          //   handleConfirmTransportationOptions(
          //     selectedPlacementOptionData.shipmentIds,
          //     false,
          //     true,
          //   );
          // } else {
          handleConfirmTransportationOptions(selectedPlacementOptionData.shipmentIds, false);
          //   setActiveTab(fbaTabs.DELIVERY_WINDOW);
          //   // Add a small delay before polling to ensure state updates are complete
          //   setTimeout(() => {
          //     pollDeliveryWindowOptions();
          //   }, 100);
          // // }
        }
      } else if (data?.errors && data.errors.length > 0) {
        setShowNotification({
          show: true,
          type: 'error',
          content: data.errors[0].message,
        });
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          content: 'An unexpected error occurred while confirming placement option',
        });
      }
    };

    const errorCallback = (error: any) => {
      setLoading(false);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to confirm placement option',
      });
    };

    dispatch(
      confirmPlacementOption({
        variables: confirmPlacementVariables,
        successCallback,
        errorCallback,
        getToken,
      }),
    );
  };

  const handleConfirmTransportationOptions = (shipmentIds: string[], toStore: boolean = false) => {
    const validateSameCarrier = (options) => {
      if (options.length <= 1) return true;
      const firstCarrier = options[0].carrier.name;
      return (
        !options.some((opt) => opt.carrier.name !== firstCarrier) ||
        setShowNotification({
          show: true,
          type: 'error',
          content: 'Please select rates from the same carrier for all shipments',
        })
      );
    };

    const mapTransportationOption = (shipmentId) => {
      const selectedOption = selectedTransportationOption[shipmentId];
      const shipmentData =
        getTransportationsOptionsResult?.data?.transportationOptionsPerShipment?.find(
          (opt) => opt.shipmentId === shipmentId,
        );

      const transportationOption = shipmentData?.transportationOptions?.find(
        (opt) =>
          opt.transportationOptionId === selectedOption?.transportationOptionId &&
          opt.carrier.alphaCode ===
            (selectedOption?.alphaCode || selectedOption?.carrier?.alphaCode),
      );

      return {
        transportOptionId: selectedOption.transportationOptionId,
        shipmentId,
        shippingSolution: transportationOption.shippingSolution,
        carrier: transportationOption.carrier,
        carrierAppointment: transportationOption.carrierAppointment,
        preconditions: transportationOption.preconditions,
        quote: transportationOption.quote,
        shippingMode: transportationOption.shippingMode,
      };
    };

    const transportationOptions = shipmentIds.map(mapTransportationOption);
    if (!validateSameCarrier(transportationOptions)) return false;

    const areAllPartnered = transportationOptions.every(
      (opt) => opt.shippingSolution === 'AMAZON_PARTNERED_CARRIER',
    );

    if (toStore) {
      localStorage.setItem('transportationOptions', JSON.stringify(transportationOptions));
      return true;
    }

    const variables = {
      input: {
        outboundOrderId: order?.outboundOrderId,
        transportationOptions: transportationOptions.map((opt: any) => ({
          transportationOptionId: opt.transportOptionId || opt.transportationOptionId,
          shipmentId: opt.shipmentId,
          carrier: opt.carrier,
        })),
      },
    };

    setLoading(true);

    const successCallback = (response: any) => {
      setLoading(false);
      if (response?.status === 'SUCCESS') {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Transportation options confirmed successfully',
        });

        if (areAllPartnered) {
          setActiveTab(fbaTabs.LABEL_GENERATION);
          pollShipmentLists();
        } else {
          setActiveTab(fbaTabs.DELIVERY_WINDOW);
          pollDeliveryWindowOptions();
        }
        setSlideOverOpen(true);
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          content:
            response?.errors?.[0]?.message ||
            'An unexpected error occurred while confirming transportation options',
        });
      }
    };

    const errorCallback = (error: any) => {
      setLoading(false);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to confirm transportation options',
      });
    };

    dispatch(
      confirmTransportationOptions({
        variables,
        successCallback,
        errorCallback,
        getToken,
        method: 'POST',
      }),
    );
  };

  const pollDeliveryWindowOptions = () => {
    setLoadingOverlay(
      'Delivery window options are being Generated.',
      '',
      loadingOverlayHtmlContent,
    );

    const intervalId = setInterval(() => {
      const inboundPlan = order?.shipmentPlanDetails;
      const selectedPlacementOptionData = inboundPlan?.selectedPlacementOption?.placementOptionId
        ? inboundPlan?.selectedPlacementOption
        : JSON.parse(localStorage.getItem('selectedPlacementOptionData'));

      const shipmentIdsList = selectedPlacementOptionData.shipmentIds || [];

      const variables = {
        input: {
          outboundOrderId: order?.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.errors?.length) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching delivery window options',
          });
          clearInterval(intervalId);
          removeLoadingOverlay();
        } else if (response?.deliveryWindowOptionsPerShipment?.length === shipmentIdsList.length) {
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Fetched delivery window options for all shipments successfully',
          });
          clearInterval(intervalId);
          removeLoadingOverlay();
        } else {
          setLoadingOverlay(
            'Delivery window options are being Generated.',
            '',
            loadingOverlayHtmlContent,
          );
          console.log('Polling for delivery window options...');
        }
      };

      const errorCallback = (error: any) => {
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to fetch delivery window options',
        });
        clearInterval(intervalId);
        removeLoadingOverlay();
      };

      dispatch(
        getDeliveryWindowOptions({
          variables,
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const pollShipmentLists = () => {
    setLoadingOverlay(
      'Shipment list with labels is being Generated.',
      '',
      loadingOverlayHtmlContent,
    );
    pollAmazonErrorsList();

    const intervalId = setInterval(() => {
      const successCallback = (response: any) => {
        if (!response) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching shipment list',
          });
          removeLoadingOverlay();
          clearInterval(intervalId);
        } else if (response.data.length > 0 && validateShipments(response.data)) {
          removeLoadingOverlay();
          setShowNotification({
            show: true,
            type: 'success',
            content: 'Fetched shipments list successfully',
          });
          setLoading(false);
          clearAllIntervals();
          clearInterval(intervalId);
        } else {
          console.log('Polling for shipment list...');
        }
      };

      const errorCallback = (error: any) => {
        removeLoadingOverlay();
        setLoading(false);
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to fetch shipment list',
        });
        clearAllIntervals();
        clearInterval(intervalId);
      };

      dispatch(
        listShipments({
          variables: {
            input: {
              search: order?.outboundOrderId,
              pagination: {
                page: 1,
                limit: 100,
              },
              'filter[status]': 'processing',
              'filter[merchantId]': order?.merchantId,
              include: ['publicUrls'],
            },
          },
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const pollAmazonErrorsList = () => {
    const intervalId = setInterval(() => {
      const variables = {
        input: {
          outboundOrderId: order?.outboundOrderId,
        },
      };

      const successCallback = (response: any) => {
        if (response?.errors?.length) {
          setShowNotification({
            show: true,
            type: 'error',
            content: response.errors[0].message || 'Error fetching Amazon errors list',
          });
          removeLoadingOverlay();
          setLoading(false);
          clearInterval(intervalId);
          clearAllIntervals();
        } else {
          console.log('Polling for errors list...');
        }
      };

      const errorCallback = (error: any) => {
        setShowNotification({
          show: true,
          type: 'error',
          content: error.message || 'Failed to fetch errors list',
        });
        clearInterval(intervalId);
      };

      dispatch(
        getErrorsInInboundPlan({
          variables,
          successCallback,
          errorCallback,
          getToken,
          method: 'GET',
        }),
      );
    }, POLLING_INTERVAL);

    addInterval(intervalId);
  };

  const handleConfirmationForDeliveryWindowOptions = (selectedDeliveryWindow) => {
    showConfirmation({
      title: 'Confirm Delivery Window Option',
      content: 'Are you sure you want to confirm the selected delivery window option?',
      onConfirm: () => handleConfirmDeliveryWindowOptions(selectedDeliveryWindow),
    });
  };

  const handleConfirmDeliveryWindowOptions = (selectedDeliveryWindow) => {
    if (!order?.outboundOrderId) return;

    // Create the payload based on selectedDeliveryWindow
    const deliveryWindowOptions = Object.keys(selectedDeliveryWindow).map((shipmentId) => ({
      deliveryWindowOptionId: selectedDeliveryWindow[shipmentId].value,
      shipmentId,
    }));

    // Check if all delivery windows are selected
    const hasUnselectedWindows = deliveryWindowOptions.some(
      (option) => !option.deliveryWindowOptionId,
    );

    if (hasUnselectedWindows) {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'Please select a delivery window for all shipments.',
      });
      return;
    }

    // Prepare variables for the action
    const variables = {
      input: {
        outboundOrderId: order.outboundOrderId,
        deliveryWindowOptions,
      },
    };

    if (deliveryWindowOptions.length === 0) {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'Please select a delivery window for all shipments.',
      });
      return;
    }

    const successCallback = (response: any) => {
      if (response?.status === 'SUCCESS') {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Delivery windows confirmed successfully!',
        });
        setActiveTab(FbaPackingLtlFlowTabsEnums.LABEL_GENERATION);
        pollShipmentLists();
      } else {
        setShowNotification({
          show: true,
          type: 'error',
          content: response?.errors?.[0]?.message || 'Failed to confirm delivery windows.',
        });
      }
    };

    const errorCallback = (error: any) => {
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Error confirming delivery windows',
      });
    };

    dispatch(
      confirmDeliveryWindowOptions({
        variables,
        successCallback,
        errorCallback,
        getToken,
      }),
    );
  };

  const hasLabelGenerationSetup = (inboundDetails) => {
    if (validateLtlFlow(inboundDetails)) {
      // For LTL flow, check if we have transportation options and they are all partnered
      const transportationOptions = inboundDetails?.selectedTransportationOption || [];
      const allPartnered =
        transportationOptions.length > 0 &&
        transportationOptions.every((opt) => opt.shippingSolution === 'AMAZON_PARTNERED_CARRIER');

      return allPartnered;
    } else {
      // For non-LTL flow, check if all carriers are partnered
      const transportationOption = inboundDetails?.selectedTransportationOption?.[0];
      return transportationOption?.shippingSolution === 'AMAZON_PARTNERED_CARRIER';
    }
  };

  const handleConfirmationForTransportationOptionsLtl = () => {
    const getShipmentIds = (): string[] | null => {
      // First try to get shipment IDs from placement options result
      if (getPlacementOptionsResult?.data?.placementOptionsWithShipmentBoxesAndItems?.length) {
        const selectedOption =
          getPlacementOptionsResult.data.placementOptionsWithShipmentBoxesAndItems.find(
            (option) => option.placementOptionId === selectedPlacementOption,
          );

        if (selectedOption?.shipmentIds) {
          return selectedOption.shipmentIds;
        }
      }

      // Then try to get from inbound plans
      const inboundPlanShipmentIds =
        order?.shipmentPlanDetails?.selectedPlacementOption?.shipmentIds;

      if (inboundPlanShipmentIds) {
        return inboundPlanShipmentIds;
      }

      // Finally try to get from localStorage
      try {
        const storedData = localStorage.getItem('selectedPlacementOptionData');
        return storedData ? JSON.parse(storedData)?.shipmentIds : null;
      } catch (error) {
        console.error('Error parsing stored placement data:', error);
        return null;
      }
    };

    const handleConfirm = () => {
      const shipmentIds = getShipmentIds();

      if (!shipmentIds) {
        setShowNotification({
          show: true,
          type: 'error',
          content: 'No shipment IDs found',
        });
        return;
      }

      handleConfirmTransportationOptions(shipmentIds, false);
    };

    showConfirmation({
      title: 'Confirm Transportation Options',
      content: 'Are you sure you want to confirm the transportation options for LTL shipments?',
      onConfirm: handleConfirm,
    });
  };

  const validateShipments = (entities) => {
    if (!Array.isArray(entities)) return false;

    const invalidShipments = entities.filter((shipment) => {
      return (
        !Array.isArray(shipment?.shipmentDetails?.boxLabels) ||
        shipment?.shipmentDetails?.boxLabels.length === 0 ||
        shipment?.shipmentDetails?.boxLabels.some(
          (label) =>
            !label?.filePath ||
            typeof label?.filePath !== 'string' ||
            label?.filePath.trim() === '',
        )
      );
    });

    return invalidShipments.length === 0;
  };

  const handleRateSelection = (
    shipmentId,
    transportationOptionId,
    uniqueId,
    carrierName,
    isPartnered,
  ) => {
    setSelectedTransportationOption((prev) => ({
      ...prev,
      [shipmentId]: {
        transportationOptionId,
        alphaCode: uniqueId?.split('_')?.[1],
        uniqueId:
          uniqueId ||
          `${transportationOptionId}_${getTransportationsOptionsResult?.data?.transportationOptionsPerShipment
            .find((opt) => opt.shipmentId === shipmentId)
            ?.transportationOptions.find(
              (rate) => rate.transportationOptionId === transportationOptionId,
            )?.carrier.alphaCode}`,
        carrierName,
        isPartnered,
      },
    }));
  };

  const handleConfirmPackingCompleted = (
    successCallback: () => void = () => {},
    errorCallback: () => void = () => {},
  ) => {
    const confirmPackingCompletedVariables = {
      input: {
        outboundOrderId: order?.outboundOrderId,
        isPackingCompleted: true,
      },
    };

    showConfirmation({
      title: 'Confirm Packing & Drop off',
      content: 'Are you sure you want to mark the packing as completed?',
      onConfirm: async () => {
        try {
          // Group tracking data by shipmentId
          const trackingDataByShipment = new Map();

          shipmentData.forEach((shipment) => {
            const shipmentId = shipment.marketplaceData.shipmentId;
            const {
              isCarrierEditDisabled,
              carrier,
              isFreightBillEditDisabled,
              freightBillNumbers,
            } = shipment;

            // Initialize tracking data for this shipment
            if (!trackingDataByShipment.has(shipmentId)) {
              trackingDataByShipment.set(shipmentId, {
                carrier: !isCarrierEditDisabled ? carrier : null,
                trackingNumbers: [],
                shipmentId,
              });
            }

            // Add tracking numbers for this shipment
            shipment.trackingNumbers.forEach((tracking) => {
              const { trackingId, boxId, isTrackingEditDisabled } = tracking;

              // Only add tracking number if it's editable and has a value
              if (!isTrackingEditDisabled && trackingId) {
                trackingDataByShipment.get(shipmentId).trackingNumbers.push({
                  boxId,
                  trackingId,
                });
              } else if (!isFreightBillEditDisabled && freightBillNumbers) {
                trackingDataByShipment.get(shipmentId).freightBillNumbers = freightBillNumbers;
              }
            });
          });

          // Filter out shipments with no updates
          const updatedTrackingData = Array.from(trackingDataByShipment.values()).filter(
            (data) => data.carrier || data.trackingNumbers.length > 0 || data.freightBillNumbers,
          );

          if (updatedTrackingData.length > 0) {
            // Update tracking info for each shipment
            const updateTrackingPromises = updatedTrackingData.map((trackingInfo) =>
              updateFulfillmentRequest(
                {
                  queryParams: {},
                  urlParams: { shipmentId: trackingInfo.shipmentId },
                },
                {
                  method: 'PATCH',
                  body: JSON.stringify({
                    carrier: trackingInfo.carrier,
                    trackingNumbers: trackingInfo.trackingNumbers,
                    freightBillNumbers: trackingInfo.freightBillNumbers,
                  }),
                },
              ),
            );

            const updateTrackingResponses = await Promise.all(updateTrackingPromises);

            // Check for failed updates
            const failedUpdates = updateTrackingResponses.filter((response) => !response.success);

            if (failedUpdates.length > 0) {
              // Show errors for failed updates
              failedUpdates.forEach((failedResponse) => {
                setShowNotification({
                  show: true,
                  type: 'error',
                  content: `Failed to update tracking for shipment ${failedResponse.variables.shipmentId}`,
                });
              });
              return;
            }

            setShowNotification({
              show: true,
              type: 'success',
              content: 'Successfully updated tracking information for all shipments',
            });
          }

          // Confirm packing completion
          dispatch(
            confirmPackingCompleted({
              variables: confirmPackingCompletedVariables,
              successCallback,
              errorCallback,
              getToken,
            }),
          );
          setSlideOverOpen(false);
          removeLoadingOverlay();
          clearAllIntervals();
          await fetchOrder();
        } catch (error) {
          setShowNotification({
            show: true,
            type: 'error',
            content: 'Failed to complete packing process',
          });
        }
      },
    });
  };

  const handleGetDescriptions = () => {
    if (activeTab === FbaPackingTabsEnums.BOX_INFORMATION) {
      return 'Enter the required details for each box, including dimensions, weight, and barcode information. Ensure the total items match the assigned packing group to maintain accuracy and completeness.';
    } else if (activeTab === FbaPackingTabsEnums.PLACEMENT_OPTIONS) {
      return 'Compare shipping rates across different locations. Select your preferred rate to see the total cost update instantly, enabling quick and informed decision-making.';
    } else if (activeTab === FbaPackingTabsEnums.PACKING_OPTIONS) {
      return 'This screen displays multiple packing options generated by Amazon, optimized for shipping efficiency and cost. Users are required to select one of these options to proceed.';
    } else if (activeTab === FbaPackingTabsEnums.LABEL_GENERATION) {
      return 'This screen generates labels for each box. Users can choose the label size, orientation, and color. Ensure the labels are properly aligned and secure before printing.';
    } else if (activeTab === FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS) {
      return 'Review the transportation options selected by Amazon. Ensure they align with your business requirements and cost-effectiveness before proceeding.';
    } else if (activeTab === FbaPackingTabsEnums.DELIVERY_WINDOW) {
      return 'Select the delivery window for each shipment. Ensure the selected window aligns with your business requirements and cost-effectiveness before proceeding.';
    }
  };

  const handleEnterTrackingManually = () => {
    if (!placementOptionForLtl?.shipmentIds) {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'No shipment IDs found',
      });
      return;
    }

    // Create new rates object with "other" carrier for each shipment
    const newRates = {};
    placementOptionForLtl?.shipmentIds?.forEach((shipmentId) => {
      const shipmentOptions = transportationRates?.find(
        (option) => option.shipmentId === shipmentId,
      );

      if (shipmentOptions) {
        const otherOption = shipmentOptions.transportationOptions.find(
          (option) => option.carrier.name.toLowerCase() === 'other',
        );

        if (otherOption) {
          newRates[shipmentId] = {
            transportationOptionId: otherOption.transportationOptionId,
            uniqueId: `${otherOption.transportationOptionId}_${otherOption.carrier.alphaCode}`,
            alphaCode: otherOption.carrier.alphaCode,
            carrierName: otherOption.carrier.name,
          };
        }
      }
    });

    // Set the flag to true before updating state
    setShouldConfirmTransportation(true);

    // Update the state
    setSelectedTransportationOption(newRates);
  };

  const handlePackingStatus = async (status: 'paused' | 'pending') => {
    const isPausing = status === 'paused';

    try {
      await updateOrderStatus(
        {
          queryParams: {},
          urlParams: { outboundOrderId: order?.outboundOrderId },
        },
        {
          method: 'PATCH',
          body: JSON.stringify({ status }),
        },
      );

      setShowNotification({
        show: true,
        type: 'success',
        content: `Packing ${isPausing ? 'paused' : 'resumed'} successfully`,
      });

      if (isPausing) {
        setSlideOverOpen(false);
        removeLoadingOverlay();
        clearAllIntervals();
      } else {
        await fetchOrder();
      }
    } catch (error) {
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || `Failed to ${isPausing ? 'pause' : 'resume'} packing`,
      });
    }
  };

  const handleMoreActions = () => {
    return [
      {
        name: 'Pause Packing',
        icon: '',
        onClick: () => {
          handlePackingStatus('paused');
        },
      },
      {
        name: 'Restart Packing',
        icon: '',
        onClick: () => {
          handleCancelInboundPlan();
        },
      },
    ].filter(Boolean);
  };

  const handleTransformShipmentData = (data: any[]) => {
    return data.map((shipment) => {
      const transformedShipment = { ...shipment };

      // Ensure trackingNumbers array is populated based on boxes
      if (
        !transformedShipment.trackingNumbers ||
        transformedShipment.trackingNumbers.length === 0
      ) {
        transformedShipment.trackingNumbers = transformedShipment.boxes.map((box) => ({
          boxId: box.boxId,
          trackingId: '',
          isTrackingEditDisabled: false, // Allow editing since there's no trackingId initially
        }));
      } else {
        transformedShipment.trackingNumbers = transformedShipment.trackingNumbers.map(
          (tracking) => {
            const correspondingBox = transformedShipment.boxes.find(
              (box) => box.boxId === tracking.boxId,
            );

            if (correspondingBox) {
              // If trackingId exists, mark it as disabled
              return {
                ...tracking,
                trackingId: tracking.trackingId || '', // Ensure trackingId is empty if missing
                isTrackingEditDisabled: !!tracking.trackingId, // Disable if trackingId is already present
              };
            }

            return {
              boxId: tracking.boxId,
              trackingId: '',
              isTrackingEditDisabled: false, // Enable editing if no trackingId
            };
          },
        );
      }

      // Ensure carrier is available at the shipment level
      if (!transformedShipment.carrier) {
        transformedShipment.carrier = '';
      }
      if (!transformedShipment.freightBillNumbers) {
        transformedShipment.freightBillNumbers = [];
      }

      // Add `isCarrierEditDisabled` if the carrier already exists
      transformedShipment.isCarrierEditDisabled = !!transformedShipment.carrier;
      // Add `isFreightBillEditDisabled` if the freightBillNumbers already exists
      transformedShipment.isFreightBillEditDisabled =
        !!transformedShipment.freightBillNumbers?.length;

      return transformedShipment;
    });
  };

  const handleTrackingInfoChange = (
    shipmentId: string,
    boxId: string,
    field: string,
    value: string,
  ) => {
    const updatedShipmentData = shipmentData.map((shipment) => {
      if (shipment.marketplaceData.shipmentId === shipmentId) {
        if (field === 'freightBillNumbers') {
          return {
            ...shipment,
            freightBillNumbers: value.split(',').map((item) => item.trim()),
          };
        }

        const updatedTrackingNumbers = shipment.trackingNumbers.map((tracking) => {
          if (tracking.boxId === boxId) {
            return {
              ...tracking,
              [field]: value,
            };
          }
          return tracking;
        });

        return {
          ...shipment,
          trackingNumbers: updatedTrackingNumbers,
          carrier: field === 'carrier' ? value : shipment.carrier,
        };
      }
      return shipment;
    });

    setShipmentData(updatedShipmentData);
  };

  const generateFnSKULabel = async (listing) => {
    setLoading(true);
    try {
      if (listing.marketplaceData)
        await authenticatedDownload(
          `fnsku-${listing.marketplaceData.sellerSku}`,
          `/api/v1/listings/label`,
          {
            method: 'POST',
            body: JSON.stringify({
              listingId: listing.listingId,
              height: 1.25,
              width: 2.25,
            }),
          },
        );
    } catch (e) {
      console.error(e);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Failed to download the file',
      });
    }
    setLoading(false);
  };

  const toggleProductSelection = (listingId: string) => {
    if (editedLineItems[listingId]?.isDeleted) return;

    setSelectedProductIds((prevSelected) =>
      prevSelected.includes(listingId)
        ? prevSelected.filter((id) => id !== listingId)
        : [...prevSelected, listingId],
    );
  };

  const toggleSelectAll = () => {
    const selectableItems =
      order?.lineItems
        ?.filter((item) => !editedLineItems[item.listing.listingId]?.isDeleted)
        .map((item) => item.listing.listingId) || [];

    if (selectedProductIds.length === selectableItems.length) {
      setSelectedProductIds([]);
    } else {
      setSelectedProductIds(selectableItems);
    }
  };

  const handleDownloadSelected = async () => {
    setLoading(true);
    try {
      const selectedProducts = order?.lineItems
        ?.filter((item) => selectedProductIds.includes(item.listing.listingId))
        .map((item) => item.listing);

      const limit = pLimit(5);

      const downloadPromises = selectedProducts.map((product) =>
        limit(async () => {
          try {
            await generateFnSKULabel(product);
          } catch (error) {
            console.error(`Failed to generate FNSKU for product: ${product.listingId}`, error);
            throw error;
          }
        }),
      );

      await Promise.all(downloadPromises);

      setShowNotification({
        show: true,
        type: 'success',
        content: 'Downloaded FNSKU labels successfully',
      });
    } catch (error) {
      console.error(error);
      setShowNotification({
        show: true,
        type: 'failure',
        content: 'Failed to download some files',
      });
    }
    setLoading(false);
  };

  const cancelOrder = async (outboundOrderId: string) => {
    showConfirmation({
      title: 'Cancel Outbound Order',
      content: 'Are you sure you want to cancel this outbound order?',
      onConfirm: () => handleCancelOrder(outboundOrderId),
    });
  };

  const handleCancelOrder = async (outboundOrderId: string) => {
    if (!outboundOrderId) {
      setShowNotification({
        show: true,
        type: 'error',
        content: 'Invalid outbound order ID',
      });
      return;
    }

    setLoading(true);

    const cancelAmazonInboundPlanVariables = {
      input: {
        outboundOrderId,
        isCancelOrder: true,
      },
    };

    try {
      await dispatch(
        cancelAmazonInboundPlan({
          variables: cancelAmazonInboundPlanVariables,
          successCallback: (response: any) => {
            if (response?.status === 'SUCCESS') {
              clearAllIntervals();
              dispatch(resetFbaPackingState());
              setSlideOverOpen(false);
              setShowNotification({
                show: true,
                type: 'success',
                content: 'Outbound order cancelled successfully',
              });
            } else {
              throw new Error(response?.errors?.[0]?.message || 'Failed to cancel outbound order');
            }
          },
          errorCallback: (error: any) => {
            throw new Error(error.message || 'Failed to cancel outbound order. Please try again.');
          },
          getToken,
        }),
      );

      await fetchOrder();
    } catch (error) {
      console.error('Error cancelling order:', error);
      setShowNotification({
        show: true,
        type: 'error',
        content: error instanceof Error ? error.message : 'An unexpected error occurred',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrepInfoChange = (listingId: string, field: string, value: any) => {
    setEditedLineItems((prev) => {
      const existingLineItem = prev[listingId]?.lineItem || {};
      const existingPrepDetails = existingLineItem.prepDetails || {};
      const originalPrepDetails =
        order.lineItems.find((item) => item.listing.listingId === listingId)?.prepDetails || {};

      return {
        ...prev,
        [listingId]: {
          ...prev[listingId],
          lineItem: {
            ...existingLineItem,
            prepDetails: {
              ...originalPrepDetails,
              ...existingPrepDetails,
              [field]: value,
            },
          },
        },
      };
    });
    setModifiedRows((prev) => new Set(prev.add(listingId)));
  };

  const handleDeleteRow = (listingId) => {
    setEditedLineItems((prev) => ({
      ...prev,
      [listingId]: {
        ...prev[listingId],
        isDeleted: true,
      },
    }));
    setModifiedRows((prev) => new Set(prev.add(listingId)));
  };

  const handleRestoreRow = (listingId: string) => {
    setEditedLineItems((prev) => ({
      ...prev,
      [listingId]: {
        ...prev[listingId],
        isDeleted: false,
      },
    }));
    setModifiedRows((prev) => new Set(prev.add(listingId)));
  };

  const handleUpdateOrder = async () => {
    try {
      setLoading(true);

      const updatedLineItems = order.lineItems
        .map((lineItem) => {
          const editedItem = editedLineItems[lineItem.listing.listingId];

          return {
            listingId: lineItem.listing.listingId,
            quantity: editedItem?.lineItem?.quantity || lineItem.quantity,
            prepDetails: editedItem?.lineItem?.prepDetails || lineItem.prepDetails,
            isDeleted: editedItem?.isDeleted === true,
          };
        })
        .filter(Boolean);

      const response = await updateOutboundOrder(
        {
          urlParams: { outboundOrderId: order.outboundOrderId },
          queryParams: {},
        },
        {
          method: 'PATCH',
          body: JSON.stringify({
            lineItems: updatedLineItems,
            warehouseId: order.warehouseId,
            version: order.version,
          }),
        },
      );

      if (response.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Order updated successfully',
        });
        setIsEditing(false);
        setModifiedRows(new Set());
        await fetchOrder();
      } else {
        throw new Error(response.message || 'Failed to update order');
      }
    } catch (error) {
      console.error('Error updating order:', error);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to update order. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedLineItems({});
    setModifiedRows(new Set());
  };

  return (
    <div className='p-8'>
      <div className='flex justify-between my-2'>
        <div className='flex gap-2'>
          <h1
            className='text-[#030229] text-[24px] cursor-pointer font-bold hover:underline'
            onClick={() => {
              navigate(-1);
            }}
          >
            Outbound Order{' '}
          </h1>
          <span className='my-auto font-semibold text-[18px]'>{' > Order Details'}</span>
        </div>
        <div className='flex'>
          {order?.integration.integrationName === IntegrationName.SHOPIFY &&
            !['completed', 'cancelled'].includes(order.status) && (
              <Button
                onClick={() => {
                  handleShopifyFulfillmentCreation(order.outboundOrderId);
                }}
              >
                <div>Create Fulfillment</div>
              </Button>
            )}
          {hasUnknownPrepCategory(order) && (
            <Button
              onClick={() =>
                handleBulkUpdateUnknownPrep(
                  order,
                  updateOutboundOrder,
                  setShowNotification,
                  setLoading,
                  fetchOrder,
                )
              }
              className='flex items-center gap-2 mr-2'
            >
              Update All Unknown Prep to No Prep
            </Button>
          )}
          {order?.integration.integrationName === IntegrationName.AMAZON &&
            order?.version === 'v2' &&
            order?.status !== OutBoundOrderStatus.COMPLETED &&
            order?.status !== OutBoundOrderStatus.FAILED &&
            order?.status !== OutBoundOrderStatus.CANCELLED && (
              <>
                {isEditing ? (
                  <div className='flex gap-2'>
                    <Button variant='secondary' onClick={handleCancelEdit} className='mr-2'>
                      Cancel
                    </Button>
                    <Button onClick={handleUpdateOrder} className='mr-2'>
                      Update
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => setIsEditing(true)} className='mr-2'>
                    Edit
                  </Button>
                )}
                <div
                  id='work-order-button'
                  data-tooltip-id='work-order-tooltip'
                  data-tooltip-content={
                    hasUnknownPrepCategory(order)
                      ? `Update prep category for the following ASINs: ${getUnknownPrepItemsInfo(
                          order,
                        )}`
                      : 'Click to work on this order'
                  }
                >
                  <Button
                    disabled={hasUnknownPrepCategory(order)}
                    className={hasUnknownPrepCategory(order) ? 'cursor-not-allowed' : ''}
                    onClick={() => {
                      if (order?.status === OutBoundOrderStatus.PAUSED) {
                        handlePackingStatus('pending');
                      } else {
                        setShowBoxDetailsModal(true);
                      }
                    }}
                  >
                    <Cog6ToothIcon className='w-4 my-auto' />
                    <div>Work on Order</div>
                  </Button>
                </div>

                <Tooltip
                  id='work-order-tooltip'
                  anchorId='work-order-button'
                  place='top'
                  style={{ maxWidth: '400px', zIndex: 1000 }}
                />
              </>
            )}
          {order?.integration.integrationName === IntegrationName.AMAZON && (
            <Menu as={'div'} className='relative overflow-visible'>
              <div>
                <Menu.Button className=''>
                  <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                </Menu.Button>
              </div>
              <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                <div>
                  <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                    <button
                      onClick={handleDownloadSelected}
                      disabled={selectedProductIds.length === 0}
                      className={`flex gap-2 w-full ${
                        selectedProductIds.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <div className='text-[12px] text-left'>Download Selected FNSKUs</div>
                    </button>
                  </Menu.Item>
                </div>

                {order?.version === 'v2' && !['completed', 'cancelled'].includes(order.status) && (
                  <div>
                    <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                      <button
                        onClick={() => {
                          cancelOrder(order?.outboundOrderId);
                        }}
                        className='flex gap-2 w-full'
                      >
                        <div className='text-[12px]'>Cancel Order</div>
                      </button>
                    </Menu.Item>
                  </div>
                )}
              </Menu.Items>
            </Menu>
          )}
        </div>
      </div>

      <div className='grid grid-cols-4 gap-3 mt-8'>
        <div>
          <div className='text-[#717679] text-[14px]'>Outbound Order ID</div>
          <div className='text-[#344053] text-[15px]  line-clamp-3 '>{order?.outboundOrderId}</div>
        </div>
        <div>
          <div className='text-[#717679] text-[14px]'>Client</div>
          <div className='text-[#344053] text-[15px] capitalize'>{order?.merchant?.name}</div>
        </div>
      </div>

      <div className='flex gap-4 my-4'>
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-hopstack-blue-600 sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-50'>
            <tr>
              <th scope='col' className='relative px-7 sm:w-12 sm:px-6'>
                <input
                  type='checkbox'
                  checked={
                    order?.lineItems?.length > 0 &&
                    selectedProductIds.length === order?.lineItems?.length
                  }
                  onChange={toggleSelectAll}
                  className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600'
                />
              </th>
              <th
                scope='col'
                className='py-3.5  pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Image
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 max-w-[100px] text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Product Name
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-balance text-sm font-semibold text-gray-900'
              >
                Identifiers
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Order Qty.
              </th>
              {order?.integration.integrationName === IntegrationName.AMAZON && (
                <>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Prep Information
                  </th>

                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Actions
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {order?.lineItems?.map((lineItem, index) => {
              const product = lineItem.listing;
              const isModified = modifiedRows.has(product.listingId);
              const isDeleted = editedLineItems[product.listingId]?.isDeleted;
              return (
                <tr
                  key={index}
                  className={`${isModified ? 'bg-yellow-50' : ''} ${isDeleted ? 'opacity-50' : ''}`}
                >
                  <td className='relative px-7 sm:w-12 sm:px-6'>
                    <input
                      type='checkbox'
                      checked={selectedProductIds.includes(product.listingId)}
                      onChange={() => toggleProductSelection(product.listingId)}
                      disabled={isDeleted}
                      className={`absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600 ${
                        isDeleted ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    />
                  </td>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                    {product.images && product.images.length > 0 && (
                      <img
                        className='object-scale-down h-20'
                        src={product?.images[0]}
                        height={100}
                        width={100}
                      />
                    )}
                  </td>
                  <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-[20rem]'>
                    <div className='line-clamp-3 '>{product.name}</div>
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500 mx-auto'>
                    <div className='w-48 text-wrap mx-auto'>
                      {product?.marketplaceData?.marketplaceId &&
                        product?.marketplaceData?.asin && (
                          <a
                            href={`${
                              getMarketplace(product.marketplaceData.marketplaceId).marketplaceUrl
                            }/dp/${product.marketplaceData.asin}`}
                            target='blank'
                            className='hover:underline'
                          >
                            {' '}
                            <span className='font-bold'>ASIN: </span>
                            {product.marketplaceData.asin + '\n'}
                          </a>
                        )}
                      <br />
                      {product?.marketplaceData?.fnSku && (
                        <>
                          <span className='font-bold'>FNSKU: </span>
                          {product.marketplaceData.fnSku + '\n'}
                          <br />
                        </>
                      )}
                      {product?.marketplaceData?.sellerSku && (
                        <>
                          <span className='font-bold'>Seller SKU: </span>
                          {product.marketplaceData.sellerSku + '\n'}
                          <br />
                        </>
                      )}
                      {lineItem?.selectedExpiry && (
                        <>
                          <span className='font-bold'>Expiry Date: </span>
                          {lineItem?.selectedExpiry + '\n'}
                          <br />
                        </>
                      )}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lineItem?.quantity || 0}
                  </td>
                  {order?.integration.integrationName === IntegrationName.AMAZON && (
                    <>
                      <td className='px-3 py-4 text-sm text-gray-500'>
                        <div>
                          <div>Prep Owner: {lineItem.prepOwner}</div>
                          <div>Label Owner: {lineItem.labelOwner}</div>
                        </div>
                        {isEditing ? (
                          <div className='space-y-2'>
                            <div>Prep Category:</div>
                            <Dropdown
                              items={Object.values(PrepCategoryEnum).map((item) => ({
                                id: item,
                                value: item,
                              }))}
                              selectedItem={(() => {
                                const category =
                                  editedLineItems[product.listingId]?.lineItem?.prepDetails
                                    ?.prepCategory || lineItem.prepDetails?.prepCategory;
                                return category ? { id: category, value: category } : null;
                              })()}
                              setSelectedItem={(item) =>
                                handlePrepInfoChange(product.listingId, 'prepCategory', item.value)
                              }
                              getItemId={(item) => item.id}
                              getItemDisplayValue={(item) => item.value}
                              placeholder='Select prep category'
                              className='w-full z-auto'
                            />

                            <div>Prep Types:</div>
                            <MultiDropdown
                              items={Object.values(PrepTypeEnum).map((item) => ({
                                id: item,
                                value: item,
                              }))}
                              selectedItems={(() => {
                                const types =
                                  editedLineItems[product.listingId]?.lineItem?.prepDetails
                                    ?.prepTypes ||
                                  (lineItem.prepDetails?.prepTypes
                                    ? Array.isArray(lineItem.prepDetails.prepTypes)
                                      ? lineItem.prepDetails.prepTypes
                                      : [lineItem.prepDetails.prepTypes]
                                    : []);
                                return types.map((type) => ({ id: type, value: type }));
                              })()}
                              setSelectedItems={(items) =>
                                handlePrepInfoChange(
                                  product.listingId,
                                  'prepTypes',
                                  items.map((item) => item.value),
                                )
                              }
                              getItemId={(item) => item.id}
                              getItemDisplayValue={(item) => item.value}
                              placeholder='Select prep types'
                              className='w-full z-auto'
                            />
                          </div>
                        ) : (
                          <div>
                            <div>Prep Category: {lineItem.prepDetails?.prepCategory}</div>
                            <div>Prep Types: {lineItem.prepDetails?.prepTypes?.join(', ')}</div>
                          </div>
                        )}
                      </td>

                      <td className='px-3 py-4 text-sm text-gray-500' style={{ opacity: 1 }}>
                        {editedLineItems[product.listingId]?.isDeleted ? (
                          <button
                            onClick={() => handleRestoreRow(product.listingId)}
                            disabled={!isEditing}
                            className={`text-green-600 hover:text-green-900 ${
                              !isEditing ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            title='Restore item'
                          >
                            <ArrowPathIcon className='h-5 w-5' />
                          </button>
                        ) : (
                          <button
                            onClick={() => handleDeleteRow(product.listingId)}
                            disabled={!isEditing}
                            className={`text-red-600 hover:text-red-900 ${
                              !isEditing ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            title='Delete item'
                          >
                            <TrashIcon className='h-5 w-5' />
                          </button>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <SlideOver
        open={slideOverOpen}
        isCrossIconVisible={false}
        setOpen={() => {}}
        XXLarge={true}
        title={
          <div className='flex justify-between p-2'>
            <HeaderWithArrow
              isArrow={false}
              headerTitle={activeTab}
              description={
                <div className='flex flex-col justify-center'>
                  <p>
                    OrderId: {order?.outboundOrderId} | Plan ID:{' '}
                    {order?.shipmentPlanDetails?.amazonInboundPlanId}
                  </p>
                  <p>{handleGetDescriptions()}</p>
                </div>
              }
            />
          </div>
        }
      >
        <div className='flex h-full flex-col gap-6 px-8 font-inter'>
          <CustomTabSelection
            tabs={getAvailableTabs().map((tab: string) => ({
              label: tab,
              value: tab,
              disabled: tab !== activeTab,
            }))}
            activeTab={activeTab}
            onTabChange={(value: string) => {
              setActiveTab(value as FbaPackingTabsEnums | FbaPackingLtlFlowTabsEnums);
            }}
          />

          <div className='flex-grow overflow-y-auto'>
            {activeTab === FbaPackingTabsEnums.PACKING_OPTIONS && (
              <SelectPackingOptions
                packingOptions={packingOptionsResult?.data?.packingOptionsWithGroupItems || []}
                selectedPackingOption={selectedPackingOption}
                setSelectedPackingOption={setSelectedPackingOption}
              />
            )}
            {activeTab === FbaPackingTabsEnums.BOX_INFORMATION && (
              <PackingBoxInformation
                packingOptions={packingOptions}
                selectedPackingOption={
                  isLTLWorkflow() ? selectedPlacementOption : selectedPackingOption
                }
                boxTypes={[{ name: 'Add Custom', height: 0, width: 0, length: 0 }]}
                formattedGroups={formattedGroups}
                setFormattedGroups={setFormattedGroups}
                isLtlFlow={isLTLWorkflow()}
                setShowNotification={setShowNotification}
                order={order}
              />
            )}
            {activeTab === FbaPackingTabsEnums.PLACEMENT_OPTIONS && (
              <SelectPlacementOptions
                placementOptions={
                  getPlacementOptionsResult.data?.placementOptionsWithShipmentBoxesAndItems || []
                }
                selectedPlacementOption={selectedPlacementOption}
                setSelectedPlacementOption={setSelectedPlacementOption}
                setSelectedRates={setSelectedTransportationOption}
                selectedRates={selectedTransportationOption}
                setShowNotification={setShowNotification}
                pollTransportationOptions={() => {
                  const shipmentIdsList =
                    getPlacementOptionsResult?.data?.placementOptionsWithShipmentBoxesAndItems
                      .map((grouping) => grouping.shipmentIds)
                      .flat()
                      .filter(Boolean);

                  pollTransportationOptions(shipmentIdsList?.length, isLTLWorkflow());
                }}
                isLtlFlow={isLTLWorkflow()}
                order={order}
              />
            )}
            {activeTab === FbaPackingTabsEnums.DELIVERY_WINDOW && (
              <SelectDeliveryOptions
                data={getDeliverWindowOptionsResult.data?.deliveryWindowOptionsPerShipment || []}
                selectedDeliveryWindow={selectedDeliveryWindow as any}
                setSelectedDeliveryWindow={setSelectedDeliveryWindow as any}
              />
            )}
            {activeTab === FbaPackingTabsEnums.LABEL_GENERATION && (
              <FbaShipmentDetails
                data={shipmentData}
                setShowNotification={setShowNotification}
                onChangeData={handleTrackingInfoChange}
              />
            )}
            {activeTab === FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS && (
              <div>
                <FbaRateShopping
                  pollTransportationOptions={() => {
                    const shipmentIdsList =
                      order?.shipmentPlanDetails?.selectedPlacementOption?.shipmentIds ||
                      JSON.parse(localStorage.getItem('selectedPlacementOptionData'))?.shipmentIds;
                    pollTransportationOptions(shipmentIdsList?.length, true);
                  }}
                  order={order as any}
                  placementOption={placementOptionForLtl}
                  isLtlFlow={isLTLWorkflow()}
                  selectedRates={selectedTransportationOption}
                  setSelectedRates={setSelectedTransportationOption as any}
                  transportationOptions={transportationRates}
                  onRateSelect={handleRateSelection as any}
                  onCancel={() => {}}
                  onConfirm={() => {}}
                  setShowNotification={setShowNotification}
                />
              </div>
            )}
          </div>

          {/* Footer */}
          <div className='sticky bottom-0 z-30 flex w-full items-center justify-end gap-6 bg-white p-4'>
            <div className='flex w-full justify-end gap-6'>
              <CustomPopover
                actions={handleMoreActions()}
                styles={{ bottom: '3rem' }}
                bgColorVisible={false}
                triggerElement={(open) => {
                  return (
                    <Button
                      variant='secondary'
                      className='border-none text-base font-medium'
                      onClick={() => {}}
                    >
                      More Actions{' '}
                      {open ? (
                        <ChevronDownIcon className='ml-1 h-6 w-6' />
                      ) : (
                        <ChevronUpIcon className='ml-1 h-6 w-6' />
                      )}
                    </Button>
                  );
                }}
              />
              {activeTab === FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS && (
                <Button
                  variant='primary'
                  className='text-base font-medium'
                  onClick={() => {
                    showConfirmation({
                      title: 'Enter Tracking Manually',
                      content: 'Are you sure you want to enter tracking manually?',
                      onConfirm: handleEnterTrackingManually,
                    });
                  }}
                >
                  Enter Tracking Manually
                </Button>
              )}
              <Button
                variant='primary'
                disabled={handleGetConfirmCtaText() === 'Confirm'}
                onClick={() => {
                  if (FbaPackingTabsEnums.PACKING_OPTIONS === activeTab) {
                    handleConfirmPackingOptions();
                  } else if (FbaPackingTabsEnums.BOX_INFORMATION === activeTab) {
                    handleSetPackingInformation(formattedGroups);
                  } else if (FbaPackingTabsEnums.PLACEMENT_OPTIONS === activeTab) {
                    handleConfirmationForPlacementOptions();
                  } else if (FbaPackingLtlFlowTabsEnums.TRANSPORTATION_OPTIONS === activeTab) {
                    handleConfirmationForTransportationOptionsLtl();
                  } else if (FbaPackingTabsEnums.DELIVERY_WINDOW === activeTab) {
                    handleConfirmationForDeliveryWindowOptions(selectedDeliveryWindow);
                  } else if (FbaPackingTabsEnums.LABEL_GENERATION === activeTab) {
                    handleConfirmPackingCompleted();
                  }
                }}
              >
                {handleGetConfirmCtaText()}
              </Button>
            </div>
          </div>
        </div>
      </SlideOver>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => {
          if (confirmationConfig?.onClose) {
            confirmationConfig.onClose();
          }
          setShowConfirmationModal(false);
        }}
        onConfirm={() => {
          if (confirmationConfig?.onConfirm) {
            confirmationConfig.onConfirm();
          }
          setShowConfirmationModal(false);
        }}
        title={confirmationConfig?.title || ''}
        content={confirmationConfig?.content}
      />

      <ModalV2
        isOpen={showBoxDetailsModal}
        onClose={() => setShowBoxDetailsModal(false)}
        title='Do You Know the Box Details for Your Shipment?'
        maxWidth='740px'
      >
        <div className='font-inter'>
          <p>
            If you already know the dimensions and weight of the boxes, we&apos;ll move ahead with
            LTL shipping. If not, we&apos;ll suggest the best shipping option based on your items
            (either small parcel or LTL).
          </p>
          <div className='mt-5 flex w-full items-center justify-end gap-5'>
            <Button
              className='text-base font-medium'
              variant='secondary'
              onClick={() => {
                setShowBoxDetailsModal(false);
                handleStartAmazonInboundCreation(false);
              }}
            >
              Yes, I have Box Details for LTL
            </Button>
            <Button
              className='text-base font-medium'
              onClick={() => {
                setShowBoxDetailsModal(false);
                handleStartAmazonInboundCreation(true);
              }}
              variant='primary'
            >
              No, Generate SP/LTL Options for me
            </Button>
          </div>
        </div>
      </ModalV2>
    </div>
  );
};

export default OutboundDetail;
