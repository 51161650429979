import React, { useState, useRef, useEffect } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// Define the props for the Dropdown component
interface DropdownProps<T> {
  items: T[];
  selectedItem: T | null;
  setSelectedItem: (item: T) => void;
  getItemId: (item: T) => string | number;
  getItemDisplayValue: (item: T) => string;
  placeholder?: string;
  className?: string;
}

/**
 * Dropdown Component
 *
 * This component renders a customizable dropdown selector for any type of items.
 * It allows users to select an item from a list of options, with customizable id and display value getters.
 *
 * @template T - The type of items to be selected
 * @param {DropdownProps<T>} props - The props for the component
 * @returns {JSX.Element} The rendered Dropdown component
 */
function Dropdown<T>({
  items,
  selectedItem,
  setSelectedItem,
  getItemId,
  getItemDisplayValue,
  placeholder = 'Select an item',
  className = '',
}: DropdownProps<T>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (item: T) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  return (
    <Listbox value={selectedItem} onChange={handleSelect} as='div'>
      <div ref={dropdownRef} className={`relative mt-1 ${className}`}>
        <ListboxButton
          onClick={() => setIsOpen(!isOpen)}
          className='relative capitalize w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-hopstack-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'
        >
          <span className='block truncate'>
            {selectedItem ? getItemDisplayValue(selectedItem) : placeholder}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </span>
        </ListboxButton>
        <Transition
          show={isOpen}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <ListboxOptions
            static
            className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 border border-gray-200 focus:outline-none sm:text-sm divide-y divide-gray-100'
          >
            {items.map((item) => (
              <ListboxOption
                key={getItemId(item)}
                className={({ active }) =>
                  `relative cursor-default capitalize select-none py-2.5 pl-10 pr-4 ${
                    active ? 'bg-hopstack-blue-50 text-hopstack-blue-900' : 'text-gray-900'
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {getItemDisplayValue(item)}
                    </span>
                    {selected ? (
                      <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-hopstack-blue-600'>
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}

export default Dropdown;
